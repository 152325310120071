import { SharedAngular } from '@Client/@types/sharedAngular';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import IFlowForMaintenance from '@Shared.Angular/@types/core/contracts/queryModel/flows/flowForMaintenance';
import IStepForMaintenance from '@Shared.Angular/@types/core/contracts/queryModel/flows/stepForMaintenance';
import { IConfirmDialogOptions } from '@Shared.Angular/flowingly.services/dialog.service';
import { IScope, ITimeoutService } from 'angular';

class MaintenanceFlowsController {
  static $inject = [
    'maintenanceService',
    'dialogService',
    '$scope',
    'flowinglyDiagramService',
    '$timeout',
    'permissionsService',
    'flowinglyConstants'
  ];

  loading = false;
  errorMessage = '';
  errorMessageUserGroup = '';
  businesses = [];
  userEmails: {
    email: string;
    id: Guid;
  }[];
  teams = [];
  selectedBusinessId: Guid;
  selectedUserEmail = null;
  selectedTeam = null;
  accessActorType: 'user' | 'team';
  lastStepAssignedTeam = { id: '', name: '' };
  lastStepAssignedUser = { id: '', fullName: '' };
  flowAccessDetails: {
    id: Guid;
    name: string;
    hasAccess: boolean;
    isUser: boolean;
    flowId: Guid;
    businessId: Guid;
  };
  showAccessMessage = false;
  flowModel = null;
  flow: IFlowForMaintenance;
  flowId: Guid;
  hasActionsPermission = false;
  flowIssues: string[];
  flowRecommendations: string[];
  recommendations = {
    DELETE_STEP:
      'Delete the incomplete Step so the prior Step can be resubmitted.',
    DELETE_STEP_CHILD_FLOW:
      'Delete the incomplete Step so the prior Step can be resubmitted, ' +
      "there's no automatic delete for Steps of child Flows available yet.",
    DELETE_STEP_MULTIPLE:
      'Delete the incomplete Steps so the prior Steps can be resubmitted, ' +
      "there's no automatic delete for multiple active Steps available yet.",
    FIX_ACTIVITY_NAME:
      'Before Step(s) can be resubmitted the Flow ActivityName(s) must be updated ' +
      'to reference the Step(s) requiring resubmission. ' +
      'WARNING: If the missing Step(s) are before or after a Diverge or Merge this is complicated!',
    RESUBMIT_STEP:
      'Try resubmit the last completed Step to create the next Step.'
  };
  IDENTIFIER_VALIDATION_MESSAGE = 'Please enter Flow ID or Identifier';
  USER_VALIDATION_MESSAGE = 'Please select a User';
  TEAM_VALIDATION_MESSAGE = 'Please select a Team';

  constructor(
    private maintenanceService: MaintenanceService,
    private dialogService: SharedAngular.DialogService,
    private $scope: IScope,
    private flowinglyDiagramService: SharedAngular.FlowinglyDiagramService,
    private $timeout: ITimeoutService,
    private permissionsService: SharedAngular.PermissionsService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants
  ) {
    this.$scope.$watch('this.selectedUserEmail', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showAccessMessage = false;
      }
    });

    this.hasActionsPermission =
      this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.ADMINISTRATION_ACTIONS
      );

    this.$scope.$parent.$watch(
      '$ctrl.selectedBusiness',
      (value: IBusinessDetail) => {
        this.selectedBusinessId = value?.id;
        this.flow = null;
        this.flowId = null;
        this.errorMessage = '';
        this.loading = this.selectedBusinessId == undefined;
        this.flowModel = null;
      }
    );
  }

  loadFlow(identifier: string) {
    this.clear();
    this.flow = null;
    this.flowModel = null;
    if (this.isInputEmpty(identifier)) {
      this.showValidationErrorForFlowInput(this.IDENTIFIER_VALIDATION_MESSAGE);
      return;
    }
    this.loading = true;
    this.errorMessage = '';
    this.maintenanceService
      .getFlow(this.selectedBusinessId, identifier)
      .then((response) => {
        this.loading = false;
        if (response.success) {
          const flow = response.dataModel;
          if (flow.steps?.length > 0) {
            const lastStep = flow.steps.slice(-1)[0];
            if (lastStep.assignedGroup) {
              this.lastStepAssignedTeam = {
                id: lastStep.assignedGroup.id,
                name: lastStep.assignedGroup.name
              };
              this.accessActorType = 'team';
            } else if (lastStep.assignedUser) {
              this.lastStepAssignedUser = {
                id: lastStep.assignedUser.id,
                fullName: lastStep.assignedUser.name
              };
              this.lastStepAssignedTeam = {
                id: '',
                name: ''
              };
              this.accessActorType = 'user';
            }
          }

          this.identifyFlowIssues(flow);
          flow.steps.forEach((step) => {
            step.createdDate = step.createdDate
              .replace('T', ' ')
              .substring(0, 23);
            step.completedDate = step.completedDate
              ? step.completedDate.replace('T', ' ').substring(0, 23)
              : '';
          });
          this.flow = flow;
          this.getUserOrGroup();
        } else {
          this.errorMessage = response.errorMessage;
        }
      });
  }

  cancelFlow(flowId: Guid, changeRequestId: string) {
    const options = {
      template: 'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
      controller: 'maintenanceFlowActionDialogController',
      data: {
        flowIdentifier: flowId,
        action: 'Withdraw',
        message: 'The flow will be marked as Withdrawn (AKA Cancelled).',
        isFlow: true
      }
    };
    this.dialogService.showDialog(options).then((actionConfirmed) => {
      if (actionConfirmed !== true) {
        return;
      }

      this.loading = true;
      this.maintenanceService
        .cancelFlow(flowId, changeRequestId, this.selectedBusinessId)
        .then((response) => {
          this.loading = false;

          if (response.Success) {
            this.loadFlow(flowId);
          } else {
            const options = {
              headerText: 'Error',
              message: response.ErrorMessage
            };
            this.dialogService.showMessageDialog(options);
          }
        });
    });
  }

  activateFlow(flowId: Guid, changeRequestId: string) {
    const options = {
      template: 'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
      controller: 'maintenanceFlowActionDialogController',
      data: {
        flowIdentifier: flowId,
        action: 'Activate',
        message:
          'The Flow\'s status will be set to "In Progress" so the current Step(s) can be completed.',
        isFlow: true
      }
    };
    this.dialogService.showDialog(options).then((actionConfirmed) => {
      if (actionConfirmed !== true) {
        return;
      }
      this.loading = true;
      this.maintenanceService.activateFlow(flowId, changeRequestId).then(() => {
        this.loading = false;
        this.loadFlow(flowId);
      });
    });
  }

  markFlowAsCompleted(flowId: Guid, changeRequestId: string) {
    const options = {
      template: 'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
      controller: 'maintenanceFlowActionDialogController',
      data: {
        flowIdentifier: flowId,
        action: 'Complete',
        message: 'The flow will be marked as Completed!',
        isFlow: true
      }
    };
    this.dialogService.showDialog(options).then((actionConfirmed) => {
      if (actionConfirmed !== true) {
        return;
      }

      this.loading = true;
      this.maintenanceService
        .markFlowAsCompleted(flowId, changeRequestId, this.selectedBusinessId)
        .then((response) => {
          this.loading = false;

          if (response.Success) {
            this.loadFlow(flowId);
          } else {
            const options = {
              headerText: 'Error',
              message: response.ErrorMessage
            };
            this.dialogService.showMessageDialog(options);
          }
        });
    });
  }

  toggleFlowDeletedState(flowId: string, changeRequestId: string) {
    let dialogOptions: IConfirmDialogOptions = {
      title: `Delete '${this.flow.identifier}'?`,

      message: `You are about to delete ${this.flow.identifier}. If the flow is in progress, it will be marked as deleted.`,
      confirmationWord: 'DELETE',
      actionButtonClass: 'red'
    };

    if (this.flow.isDeleted) {
      dialogOptions = {
        title: `Restore Flow "${this.flow.identifier}"`,
        message: `Are you sure you want to restore this flow ${this.flow.identifier} ?`
      };
    }
    this.dialogService
      .showConfirmDialog(this.$scope, dialogOptions)
      .then(() => {
        this.loading = true;
        const api = this.flow.isDeleted
          ? this.maintenanceService.restoreFlow
          : this.maintenanceService.deleteFlow;
        return api(flowId, changeRequestId, this.selectedBusinessId);
      })
      .then(() => {
        this.loading = false;
        this.loadFlow(flowId);
      });
  }

  setFlowAccess() {
    this.maintenanceService
      .getFlowAccess(
        this.isAccessActorTypeUser()
          ? this.selectedUserEmail.id
          : this.selectedTeam.id,
        this.flow.id
      )
      .then(
        (actorHasAccess) => {
          this.flowAccessDetails = {
            hasAccess: actorHasAccess,
            name: this.isAccessActorTypeUser()
              ? this.selectedUserEmail.fullName
              : this.selectedTeam.name,
            id: this.isAccessActorTypeUser()
              ? this.selectedUserEmail.id
              : this.selectedTeam.id,
            isUser: this.isAccessActorTypeUser(),
            flowId: this.flow.id,
            businessId: this.selectedBusinessId
          };
          this.loading = false;
          this.dialogService
            .showDialog({
              template:
                'Client/runner.maintenance/flows/runner.maintenance.flows.detail.tmpl.html',
              controller: 'maintenanceFlowDetailController',
              appendClassName: 'ngdialog-normal',
              scope: this.$scope,
              data: this.flowAccessDetails
            })
            .then((result) => {
              if (result == true) {
                this.getFlowAccess();
              }
            });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getFlowAccess() {
    this.showAccessMessage = false;
    if (
      this.isAccessActorTypeUser() &&
      (this.selectedUserEmail?.id == '' || this.selectedUserEmail === null)
    ) {
      this.showValidationErrorForUserOrTeam(this.USER_VALIDATION_MESSAGE);
    } else if (
      !this.isAccessActorTypeUser() &&
      (this.selectedTeam?.id == '' || this.selectedTeam?.id == undefined)
    ) {
      this.showValidationErrorForUserOrTeam(this.TEAM_VALIDATION_MESSAGE);
    } else {
      this.maintenanceService
        .getFlowAccess(
          this.isAccessActorTypeUser()
            ? this.selectedUserEmail.id
            : this.selectedTeam.id,
          this.flow.id
        )
        .then(
          (response) => {
            this.flowAccessDetails = {
              hasAccess: response,
              name: this.isAccessActorTypeUser()
                ? this.selectedUserEmail.fullName
                : this.selectedTeam.name,
              id: this.isAccessActorTypeUser()
                ? this.selectedUserEmail.id
                : this.selectedTeam.id,
              isUser: this.isAccessActorTypeUser(),
              flowId: this.flow.id,
              businessId: this.selectedBusinessId
            };
            this.loading = false;
            this.showAccessMessage = true;
            this.errorMessageUserGroup = '';
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  deleteStep(step: IStepForMaintenance) {
    const businessId = this.selectedBusinessId;
    const options = {
      template: 'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
      controller: 'maintenanceFlowActionDialogController',
      data: {
        stepName: step.name,
        action: 'Delete',
        message:
          'The targeted Step will be deleted and the previous Step will be made active, ' +
          "don't do this if the targeted Step is the first Step after a Diverge or Merge!"
      }
    };
    this.dialogService.showDialog(options).then((actionConfirmed) => {
      if (actionConfirmed !== true) {
        return;
      }

      this.loading = true;
      this.maintenanceService
        .deleteStep(step.id, step.flowId, businessId)
        .then((response) => {
          this.loading = false;

          if (response.success) {
            this.loadFlow(this.flow.id);
          } else {
            const options = {
              headerText: 'Error',
              message: response.errorMessage
            };
            this.dialogService.showMessageDialog(options);
          }
        });
    });
  }

  resubmitStep(stepId: Guid, stepName: string) {
    const flowId = this.flow.id;
    const businessId = this.selectedBusinessId;
    const options = {
      template: 'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
      controller: 'maintenanceFlowActionDialogController',
      data: {
        stepName: stepName,
        action: 'Resubmit',
        message:
          'The Step will be resubmitted with the same data as was originally submitted, in the hope ' +
          'that the error which prevented the next Step from being created is now fixed, or was transient.'
      }
    };
    this.dialogService.showDialog(options).then((actionConfirmed) => {
      if (actionConfirmed !== true) {
        return;
      }

      this.loading = true;
      this.maintenanceService
        .resubmitStep(stepId, flowId, businessId)
        .then((response) => {
          this.loading = false;

          if (response.success) {
            this.loadFlow(flowId);
          } else {
            const options = {
              headerText: 'Error',
              message: response.errorMessage
            };
            this.dialogService.showMessageDialog(options);
          }
        });
    });
  }

  isAccessActorTypeUser() {
    return this.accessActorType == 'user';
  }

  isInputEmpty(input: string) {
    return input === undefined || input === null || input.length < 1;
  }

  showValidationErrorForFlowInput(validationMessage: string) {
    this.errorMessage = validationMessage;
  }

  showValidationErrorForUserOrTeam(validationMessage: string) {
    this.errorMessageUserGroup = validationMessage;
  }

  identifyFlowIssues(flow: IFlowForMaintenance) {
    this.flowIssues = [];
    this.flowRecommendations = [];

    if (flow.status === 'In Progress') {
      this.identifyPartiallyCreatedSteps(flow, this.flowIssues);
      this.identifyInvalidActivity(flow, this.flowIssues);
      this.identifyStalledFlow(flow, this.flowIssues);
    }
  }

  onRadioChange() {
    this.lastStepAssignedUser = { id: '', fullName: '' };
    this.lastStepAssignedTeam = { id: '', name: '' };
    this.clear();
    this.getUserOrGroup();
  }

  clear() {
    this.showAccessMessage = false;
    this.errorMessageUserGroup = '';
  }

  onTeamChange() {
    this.clear();
  }

  getUserOrGroup() {
    this.loading = true;
    if (this.isAccessActorTypeUser()) {
      this.maintenanceService
        .getUsersWithOptions(null, this.selectedBusinessId)
        .then((result) => {
          this.userEmails = result.users.map((user) => ({
            email: user.email,
            id: user.id
          }));
          this.selectedUserEmail = this.lastStepAssignedUser;
          this.loading = false;
        });
    } else {
      this.maintenanceService
        .getTeams(this.selectedBusinessId)
        .then((response) => {
          this.teams = response.dataModel.map((team) => ({
            id: team.id,
            name: team.name
          }));
          this.teams.unshift({ id: '', name: 'Select Team' });
          this.selectedTeam = this.lastStepAssignedTeam;
          this.loading = false;
        });
    }
  }

  identifyPartiallyCreatedSteps(flow: IFlowForMaintenance, issues: string[]) {
    const problemSteps =
      flow.steps.filter(
        (step) => step.status === 'Todo' && step.fieldCount === 0
      ) || [];
    problemSteps.forEach((step) => {
      issues.push(
        `Incomplete Step "${step.name}" has no fields` +
          ' - probably due to an exception during Step creation.'
      );

      if (problemSteps.length === 1) {
        if (step.flowId === flow.id) {
          step.canDelete = true;
          this.addRecommendation(this.recommendations.DELETE_STEP);
        } else {
          this.addRecommendation(this.recommendations.DELETE_STEP_CHILD_FLOW);
        }
      } else {
        this.addRecommendation(this.recommendations.DELETE_STEP_MULTIPLE);
      }
    });
  }

  identifyInvalidActivity(flow: IFlowForMaintenance, issues: string[]) {
    const APPROVAL_GATEWAY_PREFIX = 'ApprovalRuleGateway_';
    if (flow.activityName.startsWith(APPROVAL_GATEWAY_PREFIX)) {
      issues.push(
        'Flow.ActivityName is not referencing a valid Step' +
          ' - probably due to an exception during Step creation.'
      );

      const todoSteps =
        flow.steps.filter((step) => step.status === 'Todo') || [];
      if (todoSteps.length === 0) {
        const failedStepName = flow.activityName.replace(
          APPROVAL_GATEWAY_PREFIX,
          ''
        );
        const failedStep = flow.steps.find(
          (step) => step.name === failedStepName
        );
        failedStep.canResubmit = true;
        this.addRecommendation(this.recommendations.RESUBMIT_STEP);
      } else if (todoSteps.length === 1) {
        todoSteps[0].canDelete = true;
        this.addRecommendation(this.recommendations.DELETE_STEP);
      } else {
        this.addRecommendation(this.recommendations.DELETE_STEP_MULTIPLE);
      }
    }
  }

  identifyStalledFlow(flow: IFlowForMaintenance, issues: string[]) {
    if (flow.steps.some((step) => step.status === 'Todo')) {
      return;
    }

    issues.push('Flow is incomplete but has no active steps to submit.');

    flow.steps.forEach((step) => {
      const stepFlow =
        step.flowId === flow.id
          ? flow
          : flow.childFlows.find((flow) => flow.id === step.flowId);
      if (step.name === stepFlow.activityName) {
        step.canResubmit = true;
        this.addRecommendation(this.recommendations.RESUBMIT_STEP);
      }
    });
    const flowIds = flow.childFlows.map((child) => child.id);
    flowIds.push(flow.id);
    flowIds.forEach((flowId) => {
      const flowSteps = flow.steps.filter((step) => step.flowId === flowId);
      if (
        flowSteps.length === 0 ||
        flowSteps.some((step) => step.canResubmit)
      ) {
        return;
      }
      flowSteps
        .sort((a, b) =>
          a.createdDate < b.createdDate
            ? -1
            : a.createdDate > b.createdDate
            ? 1
            : 0
        )
        .reverse();
      flowSteps[0].canResubmit = true;
      this.addRecommendation(this.recommendations.RESUBMIT_STEP);
    });
  }

  addRecommendation(recommendation: string) {
    if (this.flowRecommendations.some((fr) => fr === recommendation)) {
      return;
    }

    this.flowRecommendations.push(recommendation);
  }

  showFlowModel() {
    this.maintenanceService
      .getFlowModel(this.selectedBusinessId, this.flow.flowModelId)
      .then((flowModelDetails) => {
        this.flowinglyDiagramService.generateProcessModel({
          flow: flowModelDetails
        });
        this.flowModel = flowModelDetails;
        this.$timeout(() => {
          // First generateProcessModel doesn't render the model, but the second will...??
          this.flowinglyDiagramService.generateProcessModel({
            flow: flowModelDetails,
            allowSelect: false
          });
        }, 100);
      });
  }

  download(target: unknown, fileName: string) {
    const text = typeof target === 'string' ? target : JSON.stringify(target);
    const blob = new Blob([text], {
      type: 'application/json'
    });
    const blobUri = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUri;
    a.target = '_blank';
    a.download = fileName;
    a.click();
  }
}

angular
  .module('flowingly.runner.maintenance')
  .controller('maintenanceFlowsController', MaintenanceFlowsController);
