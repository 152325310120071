import IProcessMapNestDetails from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNestDetails';
import angular from 'angular';

export class RunnerPublicMapApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: AppConfig
  ) {}

  getPublicProcessMap(flowModelId: string) {
    return this.$http
      .get<IProcessMapNestDetails>(
        `${this.APP_CONFIG.apiBaseUrl}map/${flowModelId}`
      )
      .then((response) => {
        return response.data;
      });
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerPublicMapApiService', [
    '$http',
    'APP_CONFIG',
    ($http: angular.IHttpService, APP_CONFIG: AppConfig) =>
      new RunnerPublicMapApiService($http, APP_CONFIG)
  ]);

export type RunnerPublicMapApiServiceType = InstanceType<
  typeof RunnerPublicMapApiService
>;
