import React from 'react';

type Props = {
  label: string;
  textareaId: string;
  textareaValue: string;
  textareaRef: React.MutableRefObject<HTMLDivElement>;
  tooltipRef: React.MutableRefObject<HTMLSpanElement>;
  disabled: boolean;
  onClick: () => void;
};

const CopyTooltip = (props: Props) => {
  const {
    label,
    textareaId,
    textareaValue,
    textareaRef,
    tooltipRef,
    onClick,
    disabled
  } = props;

  return (
    <div>
      <label className="share-modal-label" htmlFor={textareaId}>
        {label}
      </label>
      <div
        style={disabled ? { opacity: 0.5 } : null}
        className="share-modal-textarea-container"
      >
        <div
          id={textareaId}
          className="share-modal-textarea"
          ref={textareaRef}
        >{textareaValue}</div>
        <span className="share-modal-icon-container tooltip" onClick={onClick}>
          <i className="fa-light fa-copy"></i>
          <span ref={tooltipRef} className="tooltiptext">
            Copied!
          </span>
        </span>
      </div>
    </div>
  );
};

export default CopyTooltip;
