/**
 * @ngdoc service
 * @name businessApiService
 * @module flowingly.runner.services
 *
 * @description A service for Business related functionality
 *
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import Guid from '@Shared.Angular/@types/guid';
import angular, { IHttpService } from 'angular';

export class BusinessApiService {
  private apiBaseUri: string;
  constructor(
    private $http: IHttpService,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {
    this.apiBaseUri = `${this.APP_CONFIG.apiBaseUrl}business`;
  }

  public getById(businessId: Guid) {
    return this.$http
      .get<IBusinessDetail>(`${this.apiBaseUri}/${businessId}`)
      .then((response) => {
        return response.data;
      });
  }

  public getByDisplayName(displayName: string) {
    return this.$http
      .get<IBusinessDetail[]>(`${this.apiBaseUri}?displayName=${displayName}`)
      .then((response) => {
        return response.data.length > 0 ? response.data[0] : null;
      });
  }

  public updateBusiness(businessDetail: IBusinessDetail) {
    return this.$http
      .put<IBusinessDetail>(
        `${this.apiBaseUri}/${businessDetail.id}`,
        businessDetail
      )
      .then((response) => {
        return response.data;
      });
  }

  public getTimeZones() {
    return this.$http
      .get<string[]>(`${this.apiBaseUri}/timezones`)
      .then((response) => {
        return response.data;
      });
  }
}

BusinessApiService.$inject = ['$http', 'APP_CONFIG'];

angular
  .module('flowingly.runner.services')
  .service('businessApiService', BusinessApiService);

export type BusinessApiServiceType = InstanceType<typeof BusinessApiService>;
