/**
 * @ngdoc service
 * @name maintenanceService
 * @module flowingly.runner.services
 *
 * @description A service responsible for maintenance area actions
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import IBusinessEntity from '@Shared.Angular/@types/core/contracts/queryModel/business/businessEntity';
import ISettingDetail from '@Shared.Angular/@types/core/contracts/config/ISettingDetail';
import { ModelerValidation } from '@Shared.Angular/flowingly.modeler.validation/@types/services';
import { ActorType } from '@Shared.Angular/interfaces/actor-type.enum';
import IFlowForMaintenance from '@Shared.Angular/@types/core/contracts/queryModel/flows/flowForMaintenance';
import IModelerWorkflowDetail from '@Shared.Angular/@types/core/contracts/queryModel/modeler/modelerWorkflowDetail';
import angular from 'angular';
import { ISearchBasicUserResultModel } from '@Shared.Angular/@types/core/contracts/queryModel/user/searchBasicUserResultModel';
import ISearchUserResultModel from '@Shared.Angular/@types/core/contracts/queryModel/user/searchUserResultModel';
import IUserMaintenanceModel from '@Shared.Angular/@types/core/contracts/queryModel/user/userMaintenanceModel';
import IActivateFlowCommand from '@Shared.Angular/@types/core/contracts/commands/workflow/activateFlowCommand';
import IGroupDetail from '@Shared.Angular/@types/core/contracts/queryModel/group/groupDetail';

angular
  .module('flowingly.runner.services')
  .factory('maintenanceService', maintenanceService);

maintenanceService.$inject = [
  '$http',
  'APP_CONFIG',
  'modelerValidationService',
  'lodashService',
  'guidService',
  'flowinglyConstants'
];

function maintenanceService(
  $http: angular.IHttpService,
  appConfig: SharedAngular.APP_CONFIG,
  modelerValidationService: ModelerValidation.ModelerValidationService,
  lodashService: Lodash,
  guidService: SharedAngular.GuidService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const service = {
    getBusinesses: getBusinesses,
    getBusinessSettings: getBusinessSettings,
    getApplicationSettings: getApplicationSettings,
    putApplicationSetting: putApplicationSetting,
    deleteApplicationSetting: deleteApplicationSetting,
    getUsersWithOptions: getUsersWithOptions,
    getTeams: getTeams,
    updateUser: updateUser,
    getFlow: getFlow,
    cancelFlow: cancelFlow,
    markFlowAsCompleted: markFlowAsCompleted,
    getFlowAccess: getFlowAccess,
    setFlowAcess: setFlowAcess,
    getPublishedFlows: getPublishedFlows,
    updateBusinessSetting: updateBusinessSetting,
    validateWorkFlow: validateWorkFlow,
    getFlowModel: getFlowModel,
    deleteStep: deleteStep,
    resubmitStep: resubmitStep,
    getEmailMethods: getEmailMethods,
    sendEmail: sendEmail,
    clearCachedSettings: clearCachedSettings,
    terminateBusiness: terminateBusiness,
    unterminateBusiness: unterminateBusiness,
    getUserBasicInfo: getUserBasicInfo,
    deleteFlow: deleteFlow,
    restoreFlow: restoreFlow,
    findBusinessOfEntity: findBusinessOfEntity,
    activateFlow: activateFlow
  };

  return service;

  function clearCachedSettings() {
    return $http.get(appConfig.apiBaseUrl + 'business/reloadSettings').then(
      function (response) {
        return response.status === 200;
      },
      function () {
        return false;
      }
    );
  }

  function updateBusinessSetting(businessId, name, value) {
    const urlPath = `business/${businessId}/settings?settingName=${encodeURIComponent(
      name
    )}`;
    return $http
      .put(appConfig.apiBaseUrl + urlPath, JSON.stringify(value))
      .then(
        function (response) {
          return response.status === 200;
        },
        function () {
          return false;
        }
      );
  }

  function getPublishedFlows(businessId) {
    ///
    /// get all published flows models
    ///
    if (!businessId) businessId = guidService.empty();
    return $http
      .get<IResponseData>(
        appConfig.apiBaseUrl + 'modeler/publishedworkflows/' + businessId
      )
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function getBusinessSettings(businessId) {
    return $http
      .get(appConfig.apiBaseUrl + `business/${businessId}/settings`)
      .then(function (response) {
        return response.data;
      });
  }

  function getApplicationSettings() {
    return $http
      .get<ISettingDetail[]>(
        appConfig.apiBaseUrl + `maintenance/applicationsettings`
      )
      .then((response) => {
        return response.data;
      });
  }

  function putApplicationSetting(setting: ISettingDetail) {
    return $http
      .put(appConfig.apiBaseUrl + `maintenance/applicationsettings`, setting)
      .then((response) => {
        return response.data;
      });
  }

  function deleteApplicationSetting(settingName: string) {
    return $http
      .delete(
        appConfig.apiBaseUrl +
          `maintenance/applicationsettings?settingName=${settingName}`
      )
      .then((response) => {
        return response.data;
      });
  }

  function getFlowModel(businessId: Guid, flowId: Guid) {
    return $http
      .get<IModelerWorkflowDetail>(
        appConfig.apiBaseUrl + `modeler/workflowdetail/${businessId}/${flowId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  function validateWorkFlow(businessId, flowId) {
    return getFlowModel(businessId, flowId).then((workflow) => {
      if (workflow.Nodes.length > 0) {
        lodashService.forEach(workflow.Nodes, function (node) {
          if (typeof node.Card === 'object') {
            return;
          }
          node.Card = JSON.parse(node.Card);
        });
        if (
          workflow.PublishType ===
          flowinglyConstants.flowModelPublishType.WORKFLOW
        ) {
          return modelerValidationService
            .validateWorkFlow(workflow, appConfig, null)
            .then((errors) => {
              if (!appConfig.disableXssValidation) {
                return modelerValidationService
                  .validateWorkflowPassXssCheck(workflow)
                  .then(
                    () => {
                      return errors;
                    },
                    () => {
                      errors.push({
                        message: 'The workflow failed at XSS validation',
                        isServerError: true
                      });
                      return errors;
                    }
                  );
              } else {
                return errors;
              }
            });
        } else {
          return [];
        }
      } else {
        return [
          {
            message: "The workflow does not have 'Nodes' data",
            isServerError: true
          }
        ];
      }
    });
  }

  function getBusinesses() {
    return $http
      .get<IBusinessDetail[]>(appConfig.apiBaseUrl + 'business')
      .then(function (response) {
        return response.data;
      });
  }

  function getUsersWithOptions(options, businessId) {
    const defaultOpts = {
      take: 100,
      skip: 0,
      page: 1,
      pageSize: 100,
      group: []
    };
    options = lodashService.defaults(options, defaultOpts);
    options.businessId = businessId;
    return $http
      .post<ISearchUserResultModel>(
        appConfig.apiBaseUrl + 'maintenance/getUsersWithOptions',
        options,
        {
          noSpinner: true
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  function getUserBasicInfo(userId: Guid, fieldName = 'id', options = null) {
    const defaultOpts = {
      businessId: null,
      take: 1,
      skip: 0,
      page: 1,
      pageSize: 1,
      group: [],
      filter: {
        filters: [{ field: fieldName, value: userId }]
      }
    };
    options = { ...defaultOpts, ...options };
    return $http
      .post<ISearchBasicUserResultModel>(
        appConfig.apiBaseUrl + 'maintenance/users/basic',
        options,
        {
          noSpinner: true
        }
      )
      .then((response) => response?.data?.users?.pop());
  }

  function getTeams(businessId: Guid) {
    return $http
      .get<IResponseData<IGroupDetail[]>>(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/teams`
      )
      .then(function (response) {
        return response.data;
      });
  }

  function updateUser(updateModel: IUserMaintenanceModel) {
    return $http.post(
      `${appConfig.apiBaseUrl}maintenance/updateUserStatuses`,
      updateModel
    );
  }

  function getFlow(businessId, identifier) {
    return $http
      .get<IResponseData<IFlowForMaintenance>>(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/flows/${identifier}`
      )
      .then(function (response) {
        return response.data;
      });
  }

  function cancelFlow(identifier, changeRequestId, businessId) {
    const request = {
      BusinessId: businessId,
      Identifier: identifier,
      ChangeRequestId: changeRequestId
    };
    return $http
      .post<IUResponseData>(
        `${appConfig.apiBaseUrl}maintenance/withdrawflow`,
        request
      )
      .then(function (response) {
        return response.data;
      }, errorResponsePascal);
  }

  function markFlowAsCompleted(identifier, changeRequestId, businessId) {
    const request = {
      BusinessId: businessId,
      Identifier: identifier,
      ChangeRequestId: changeRequestId
    };
    return $http
      .post<IUResponseData>(
        `${appConfig.apiBaseUrl}maintenance/markflowascompleted`,
        request
      )
      .then(function (response) {
        return response.data;
      }, errorResponsePascal);
  }

  function deleteFlow(
    identifier: string,
    changeRequestId: string,
    businessId: Guid
  ) {
    const url = `${
      appConfig.apiBaseUrl
    }maintenance/businesses/${businessId}/flows/${identifier}?changeRequestId=${encodeURIComponent(
      changeRequestId
    )}`;
    return $http.delete<IUResponseData>(url).then((resp) => resp.data);
  }

  function restoreFlow(
    identifier: string,
    changeRequestId: string,
    businessId: Guid
  ) {
    const url = `${
      appConfig.apiBaseUrl
    }maintenance/businesses/${businessId}/flows/${identifier}/restore?changeRequestId=${encodeURIComponent(
      changeRequestId
    )}`;
    return $http.post<IUResponseData>(url, {}).then((resp) => resp.data);
  }

  function setFlowAcess(
    id,
    flowId,
    businessId,
    isUser,
    hasAccess,
    changeRequestId
  ) {
    const request = {
      businessId: businessId,
      hasAccess: hasAccess,
      changeRequestId: changeRequestId,
      actorType: isUser ? ActorType.User : ActorType.Team
    };
    return $http
      .post<IUResponseData>(
        `${appConfig.apiBaseUrl}maintenance/flows/${flowId}/actors/${id}/relationships`,
        request
      )
      .then(function (response) {
        return response.data;
      }, errorResponsePascal);
  }

  function getFlowAccess(actorId: Guid, flowId: Guid) {
    return $http
      .get<boolean>(
        `${appConfig.apiBaseUrl}maintenance/flows/${flowId}/actors/${actorId}/relationships`
      )
      .then(function (response) {
        return response.data;
      });
  }

  function deleteStep(stepId, flowId, businessId) {
    return $http
      .delete<IResponseData>(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/flows/${flowId}/steps/${stepId}`
      )
      .then(function (response) {
        return response.data;
      }, errorResponseCamel);
  }

  function resubmitStep(stepId, flowId, businessId) {
    return $http
      .put<IResponseData>(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/flows/${flowId}/steps/${stepId}`,
        undefined
      )
      .then(function (response) {
        return response.data;
      }, errorResponseCamel);
  }

  function getEmailMethods() {
    return $http
      .get(`${appConfig.apiBaseUrl}maintenance/email/send`)
      .then(function (response) {
        return response.data;
      }, errorResponseCamel);
  }

  function sendEmail(emailMethod) {
    return $http
      .post(
        `${appConfig.apiBaseUrl}maintenance/email/send/${emailMethod}`,
        undefined
      )
      .then(function (response) {
        return response.data;
      }, errorResponseCamel);
  }

  function terminateBusiness(businessId: Guid, changeRequestId: string) {
    return $http
      .delete(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}?changeRequestId=${changeRequestId}&undo=false`
      )
      .then(function (response) {
        return response.data;
      });
  }

  function unterminateBusiness(businessId: Guid, changeRequestId: string) {
    return $http
      .delete(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}?changeRequestId=${changeRequestId}&undo=true`
      )
      .then(function (response) {
        return response.data;
      });
  }

  function findBusinessOfEntity(entityType: string, entityId: Guid) {
    return $http
      .get<IBusinessEntity>(
        `${appConfig.apiBaseUrl}maintenance/businesses?entityType=${entityType}&entityId=${entityId}`
      )
      .then(function (response) {
        return response.data;
      });
  }

  function activateFlow(flowId: Guid, changeRequestId: string) {
    const command: IActivateFlowCommand = {
      id: flowId,
      changeRequestId: changeRequestId
    };
    return $http
      .post(`${appConfig.apiBaseUrl}maintenance/activateFlow`, command)
      .then(function (response) {
        return response.data;
      });
  }

  function errorResponseCamel() {
    return {
      success: false,
      errorMessage: 'An error occurred'
    } as IResponseData;
  }

  function errorResponsePascal() {
    return {
      Success: false,
      ErrorMessage: 'An error occurred'
    } as IUResponseData;
  }
}

export type MaintenanceServiceType = ReturnType<typeof maintenanceService>;
