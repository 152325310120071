/**
 * @ngdoc service
 * @name flowModelImportService
 * @module flowingly.runner.services
 * Provides the public interface for the various file-type-specific importers.
 */

import FlowModelXmlParser from './flow.model.parsers/flow.model.xml.parser';
import Guid from '@Shared.Angular/@types/guid';
import { IPromise } from 'angular';
import IPublishWorkflowModel from '@Shared.Angular/@types/core/api/models/publishWorkflowModel';
import ISaveFlowModelOwnerModel from '@Shared.Angular/@types/core/api/models/saveFlowModelOwnerModel';
import INodeCommandData from '@Shared.Angular/@types/core/contracts/commands/modeler/nodeCommandData';
import IFlowModelCommandData from '@Shared.Angular/@types/core/contracts/commands/modeler/flowModelCommandData';
import IGroupDetail from '@Shared.Angular/@types/core/contracts/queryModel/group/groupDetail';
import FlowModelAttachmentZipParser from './flow.model.parsers/flow.model.attachment.zip.parser';
import {
  FileFolder,
  FormFieldType,
  FormFieldTypePascal
} from '../../../Flowingly.Shared.Angular/src/flowingly.services/flowingly.constants';
import FlowModelAttachmentUnsortedZipParser from './flow.model.parsers/flow.model.attachment.unsorted.zip.parser';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { IFlowModelSummary } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/flowModelSummary';
import IUserSummary from '@Shared.Angular/@types/core/contracts/queryModel/user/userSummary';
import IRoleDetail from '@Shared.Angular/@types/core/contracts/queryModel/authorisation/roleDetail';
import ICategoryDetail from '@Shared.Angular/@types/core/contracts/queryModel/category/categoryDetail';
import IModelNode from '@Shared.Angular/@types/modelNode';
import { IModelNodeLink } from '@Shared.Angular/@types/workflow';
import FlowModelVsdxParser from './flow.model.parsers/flow.model.vsdx.parser';

export interface IImportable {
  key: string;
  willImport: boolean;
  state?: ImportableFlowModelState;
  importMessage?: string;
}

export class ImportableFlowModel implements IImportable {
  key: string;
  willImport: boolean;
  state?: ImportableFlowModelState;
  importMessage?: string;
  publish: boolean;

  uniqueId: Guid;
  name: string;
  nameClash?: boolean;
  description: string;
  background: string;
  processInput: string;
  processOutput: string;
  processOwnerName: string;
  processOwnerId?: Guid;
  modelNodes: IModelNode[];
  modelLinks: IModelNodeLink[];
  flowinglyNodes: INodeCommandData[];
  categoryHierarchy: string[];
  categoryId?: Guid;
  teams: IGroupDetail[];
  file?: File;
  fileId?: string;
  attachments?: IImportableFlowModelAttachment[];
  triggerInput: string;

  public constructor(properties?: Partial<ImportableFlowModel>) {
    Object.assign(this, properties);
  }
}

export class ImportableFlowModelAttachments implements IImportable {
  key: string;
  willImport: boolean;
  state?: ImportableFlowModelState;
  importMessage?: string;

  attachments: IImportableFlowModelAttachment[];

  constructor(flowModelUniqueId: string) {
    this.key = flowModelUniqueId;
    this.willImport = false;
    this.attachments = [];
    this.state = ImportableFlowModelState.Pending;
  }
}

export enum ImportableFlowModelState {
  Pending,
  Success,
  Failure
}

export interface IImportableFlowModelFile {
  file: File;
  handled: boolean;
}

export interface IImportableFlowModelAttachment {
  name: string;
  htmlEncodedName: string;
  bytes: Uint8Array;
}

export interface IFlowModelParser {
  parseImportables(files: IImportableFlowModelFile[]): Promise<IImportable[]>;
}

angular
  .module('flowingly.services')
  .factory('flowModelImportService', flowModelImportService);

flowModelImportService.$inject = [
  'workflowModelFactory',
  'guidService',
  'workflowApiService',
  'avatarService',
  'fileService',
  'categoryApiService',
  'APP_CONFIG',
  'flowModelApiService',
  'flowinglyConstants',
  'teamApiService',
  'fflate',
  'notificationService',
  'userApiService',
  'roleApiService',
  'flowModelOwnerDialogService'
];

function flowModelImportService(
  workflowModelFactory: SharedAngular.WorkflowModelFactory,
  guidService: SharedAngular.GuidService,
  workflowApiService: SharedAngular.WorkflowApiService,
  avatarService: SharedAngular.AvatarService,
  fileService: SharedAngular.FileService,
  categoryApiService: SharedAngular.CategoryApiService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  flowModelApiService: SharedAngular.FlowModelApiService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  teamApiService: SharedAngular.TeamApiService,
  fflate: Fflate,
  notificationService: SharedAngular.NotificationService,
  userApiService: SharedAngular.UserApiService,
  roleApiService: RoleApiService,
  flowModelOwnerDialogService: FlowModelOwnerDialogService
) {
  const service = {
    getImportableFlowModels: getImportables,
    importFlowModel: importFlowModel,
    importFlowModels: importFlowModels,
    updateFlowModelOwners: updateFlowModelOwners
  };

  const parsers: IFlowModelParser[] = [
    new FlowModelXmlParser(
      guidService,
      avatarService,
      flowinglyConstants,
      notificationService
    ),
    new FlowModelAttachmentZipParser(fflate, guidService), // This must run before the UnsortedZipParser
    new FlowModelAttachmentUnsortedZipParser(fflate),
    new FlowModelVsdxParser(
      fflate,
      guidService,
      avatarService,
      notificationService
    )
  ];

  return service;

  function getImportables(files: File[]) {
    const importFiles = files.map((file) => {
      return { file, handled: false } as IImportableFlowModelFile;
    });
    const parserPromises = getImportablesFromParser(parsers, importFiles);
    return parserPromises.then((importables) => {
      const uniqueImportables = new Map(
        importables.flat().map((i) => [i.key, i])
      );
      return [...uniqueImportables.values()];
    });
  }

  function getImportablesFromParser(
    importers: IFlowModelParser[],
    files: IImportableFlowModelFile[],
    importerIndex = 0
  ): IPromise<IImportable[]> {
    if (importerIndex >= importers.length) {
      return Promise.resolve<ImportableFlowModel[]>([]);
    }
    const importer = importers[importerIndex];
    return importer.parseImportables(files).then((results) => {
      const unhandledFiles = files.filter((importFile) => !importFile.handled);
      return getImportablesFromParser(
        importers,
        unhandledFiles,
        ++importerIndex
      ).then((nextResults) => results.concat(nextResults));
    });
  }

  async function importFlowModels(
    importables: ImportableFlowModel[],
    updateCallback: (x: ImportableFlowModel[]) => void
  ) {
    const existingCategoriesLowerCase = (
      await categoryApiService.getCategories()
    ).map((category) => {
      category.name = category.name.toLowerCase();
      return category;
    });
    let flowModelWasImported = false;
    for (const importable of importables) {
      if (
        !importable.willImport ||
        importable.state !== ImportableFlowModelState.Pending
      ) {
        continue;
      }
      if (importable.categoryHierarchy?.length === 1) {
        // If this importable didn't come with a hierarchy then we need to see
        // if its Category exists anywhere in the hierarchy before we create it
        importable.categoryId = findIdOfExistingCategory(
          existingCategoriesLowerCase,
          importable.categoryHierarchy[0].toLowerCase()
        );
      }
      if (!importable.categoryId) {
        importable.categoryId = await createMissingCategories(
          existingCategoriesLowerCase,
          importable.categoryHierarchy
        );
      }

      if (importable.teams?.length > 0) {
        await createMissingTeams(importable.teams);
      }

      if (!importable.modelNodes || importable.modelNodes.length === 0) {
        importable.state = ImportableFlowModelState.Success;
        updateCallback(importables);
        continue;
      }
      await importFlowModel(importable)
        .then(() => {
          importable.state = ImportableFlowModelState.Success;
          flowModelWasImported = true;
          if (importable.fileId) {
            importables
              .filter((flowModel) => flowModel.file === importable.file)
              .forEach((flowModel) => (flowModel.fileId = importable.fileId));
          }
          updateCallback(importables);
        })
        .catch((result) => {
          importable.state = ImportableFlowModelState.Failure;
          importable.importMessage =
            result.data?.Message || 'Internal Server Error';
          updateCallback(importables);
        });
    }
    if (flowModelWasImported) {
      await flowModelApiService.linkImportedFlowModelComponents(
        flowinglyConstants.flowModelPublishType.PROCESS_MAP
      );
      await updateFlowModelOwners(importables);
    }
  }

  async function updateFlowModelOwners(importables: ImportableFlowModel[]) {
    let flowModels: IFlowModelSummary[];
    const getFlowModels = flowModelApiService.getFlowModels().then((result) => {
      flowModels = result;
    });
    let flowModelAdminRoleId: Guid;
    const getRoles = roleApiService.getRoles(false).then((roles) => {
      flowModelAdminRoleId = roles.find(
        (role) =>
          role.name ===
          flowinglyConstants.flowinglyRoles.FLOW_MODEL_ADMINISTRATOR
      ).id;
    });
    let users: IUserSummary[];
    const getUsers = userApiService.getUsers(false).then((result) => {
      users = result;
    });

    await Promise.all([getFlowModels, getRoles, getUsers]).then(() => {
      importables.forEach(async (importable) => {
        const flowModel = flowModels.find((fm) => fm.name === importable.name);
        if (!flowModel) {
          return;
        }
        const user = users.find(
          (u) => `${u.firstName} ${u.lastName}` === importable.processOwnerName
        );
        if (!user) {
          return;
        }
        await updateFlowModelOwner(flowModel.id, flowModelAdminRoleId, user);
      });
    });
  }

  async function updateFlowModelOwner(
    flowModelId: Guid,
    flowModelAdminRoleId: Guid,
    user: IUserSummary
  ) {
    if (!user.roles.some((role) => role.id === flowModelAdminRoleId)) {
      user.roles.push({ id: flowModelAdminRoleId } as IRoleDetail);
      await userApiService.editUser(user);
    }
    await flowModelOwnerDialogService
      .getExistingFlowModelOwners(flowModelId)
      .then(async (response) => {
        const owners = response.data.dataModel;
        if (owners?.some((owner) => owner.id === user.id)) {
          return;
        }
        const userIds =
          owners
            ?.filter(
              (owner) =>
                owner.searchEntityType ===
                flowinglyConstants.searchEntityType.USER
            )
            .map((owner) => owner.id) || [];
        userIds.push(user.id);
        const saveOwnerModel: ISaveFlowModelOwnerModel = {
          flowModelId: flowModelId,
          userIds: userIds,
          groupIds:
            owners
              ?.filter(
                (owner) =>
                  owner.searchEntityType ===
                  flowinglyConstants.searchEntityType.GROUP
              )
              .map((owner) => owner.id) || []
        };
        await flowModelOwnerDialogService.saveFlowModelOwners(saveOwnerModel);
      });
  }

  async function createMissingTeams(teams: IGroupDetail[]) {
    const existingTeams = (await teamApiService.getTeamsWithOptions({})).teams;
    for (const team of teams) {
      if (
        existingTeams.some((existingTeam) => existingTeam.name === team.name)
      ) {
        continue;
      }
      await teamApiService.saveTeam(team).catch(() => null);
    }
  }

  function findIdOfExistingCategory(
    existingCategoriesLowerCase: ICategoryDetail[],
    categoryNameLowerCase: string
  ) {
    if (!existingCategoriesLowerCase || !categoryNameLowerCase) {
      return;
    }
    for (const existingCategory of existingCategoriesLowerCase) {
      if (existingCategory.name === categoryNameLowerCase) {
        return existingCategory.id;
      }
      const existingSubCategoryId = findIdOfExistingCategory(
        existingCategory.subCategories,
        categoryNameLowerCase
      );
      if (existingSubCategoryId) {
        return existingSubCategoryId;
      }
    }
  }

  async function createMissingCategories(
    existingCategoriesLowerCase: ICategoryDetail[],
    categoryHierarchy: string[],
    parent?: ICategoryDetail
  ) {
    if (!categoryHierarchy || categoryHierarchy.length === 0) {
      return parent?.id;
    }
    const categoryName = categoryHierarchy[0];
    const categoryNameLowerCase = categoryName.toLowerCase();
    const existingCategory = existingCategoriesLowerCase?.find(
      (existing) => existing.name === categoryNameLowerCase
    );
    if (existingCategory) {
      return createMissingCategories(
        APP_CONFIG.enableSubCategories
          ? existingCategory.subCategories.map((category) => {
              return {
                id: category.id,
                name: category.name.toLowerCase(),
                subCategories: category.subCategories
              } as ICategoryDetail;
            })
          : existingCategoriesLowerCase,
        categoryHierarchy.slice(1),
        existingCategory
      );
    }
    const newCategory: ICategoryDetail = {
      name: categoryName,
      subCategories: []
    };
    if (APP_CONFIG.enableSubCategories && parent) {
      newCategory.parentId = parent.id;
    }
    const addCategoryResult = await categoryApiService.addCategory(newCategory);
    if (!addCategoryResult.data.success) {
      return; // Failed to make missing Category, so the Flow Model can be created with no Category
    }
    newCategory.id = addCategoryResult.data.dataModel;
    if (APP_CONFIG.enableSubCategories && parent) {
      parent.subCategories.push(newCategory);
    }

    existingCategoriesLowerCase.push({
      id: newCategory.id,
      name: newCategory.name.toLowerCase(),
      subCategories: newCategory.subCategories
    });

    return createMissingCategories(
      APP_CONFIG.enableSubCategories
        ? newCategory.subCategories
        : existingCategoriesLowerCase,
      categoryHierarchy.slice(1),
      newCategory
    );
  }

  async function createAndLinkAttachments(importable: ImportableFlowModel) {
    const regex = /<p>\[([a-zA-Z]{4,11})\|([^|]+)\|[a-zA-Z0-9-]{36}\]<\/p>/g;
    const imageExtensions = ['.jpg', 'jpeg', '.bmp', '.png', '.webp', '.gif'];
    for (const attachment of importable.attachments || []) {
      const file = new File([new Blob([attachment.bytes])], attachment.name);
      const lowerCaseAttachmentName = attachment.name.toLowerCase();
      const isImage = imageExtensions.some((ext) =>
        lowerCaseAttachmentName.endsWith(ext)
      );
      const uploadResult = isImage
        ? await fileService.uploadEmbeddedImage(file, FileFolder.Stepfiles)
        : await fileService.uploadAttachDocumentFile(file);
      const fileId: Guid = uploadResult.data;

      for (const node of importable.flowinglyNodes) {
        for (const instruction of node.Card.formElements.filter(
          (f) => f.type === FormFieldType.INSTRUCTION
        )) {
          const matches = [...instruction.value.matchAll(regex)];
          const relevantMatches = matches.filter(
            (match) =>
              match[2] === attachment.htmlEncodedName ||
              match[2].replaceAll(' ', '') ===
                attachment.htmlEncodedName.replaceAll(' ', '')
          );
          if (relevantMatches.length === 0) {
            continue;
          }
          let replacement = '';
          if (relevantMatches[0][1] === 'Image') {
            replacement = `<img data-file-id="${fileId}" />`;
          } else {
            addAttachedDocument(node.Card.formElements, fileId);
          }
          for (const match of relevantMatches) {
            instruction.value = instruction.value.replaceAll(
              match[0],
              replacement
            );
          }
        }
      }
    }
  }

  function addAttachedDocument(formElements, fileId: Guid) {
    let attachmentsField = formElements.find(
      (field) => field.type === FormFieldType.ATTACH_DOCUMENT
    );
    if (attachmentsField === undefined) {
      attachmentsField = {
        attachDocumentFileIds: [],
        conditions: [],
        defaultValueOption: 'none',
        displayName: 'Attach Documents',
        id: guidService.new(),
        name: 'field' + new Date().valueOf(),
        noLabel: true,
        type: FormFieldType.ATTACH_DOCUMENT,
        typeName: FormFieldTypePascal.ATTACH_DOCUMENT
      };
      formElements.push(attachmentsField);
    }
    attachmentsField.attachDocumentFileIds.push(fileId);
  }

  async function importFlowModel(importable: ImportableFlowModel) {
    await createAndLinkAttachments(importable);
    return workflowModelFactory
      .createFlowModel(importable.name, importable.description)
      .then((flowModel) => {
        if (importable.processOwnerName === null) {
          return flowModel;
        }
        return userApiService
          .searchUsers({
            clientId: flowModel.BusinessId,
            term: importable.processOwnerName
          })
          .then((users) => {
            const user = users?.find(
              (u) =>
                `${u.firstName} ${u.lastName}` === importable.processOwnerName
            );
            importable.processOwnerId = user?.id;
            return flowModel;
          })
          .catch(() => {
            return flowModel;
          });
      })
      .then((flowModel) => {
        const processDetail = flowModel.ProcessDetail || {};
        processDetail.background = importable.background;
        processDetail.processInput = importable.processInput;
        processDetail.processOutput = importable.processOutput;
        processDetail.processOwnerId = importable.processOwnerId;
        processDetail.triggerInput = importable.triggerInput;
        const schema = {
          class: 'gl.GraphLinksModel',
          modelData: { position: '-550 -200' },
          nodeDataArray: importable.modelNodes,
          linkDataArray: importable.modelLinks
        };
        const createFlowModelData: IFlowModelCommandData = {
          Id: guidService.empty(),
          Name: flowModel.Name,
          Description: flowModel.Description,
          CustomPlaceholder: flowModel.CustomPlaceholder,
          Category: flowModel.Category,
          Active: flowModel.Active,
          CreatedById: importable.processOwnerId || flowModel.CreatedById,
          BusinessId: flowModel.BusinessId,
          CurrentUserId: flowModel.CreatedById,
          BusinessName: null,
          CreatedDate: flowModel.CreatedDate,
          UpdatedDate: flowModel.UpdatedDate,
          SelectedFieldForSubject: flowModel.SelectedFieldForSubject,
          BackgroundColour: flowModel.BackgroundColour,
          LastModifiedById: flowModel.CreatedById,
          HasErrors: flowModel.HasErrors,
          ProcessDetail: processDetail,
          Nodes: importable.flowinglyNodes,
          FlowSchema: JSON.stringify(schema),
          CategoryId: importable.categoryId,
          ImportedId: importable.key,
          ImportedUniqueId: importable.uniqueId
        };

        return workflowApiService
          .addWorkflow(createFlowModelData)
          .then((workflowResponse) => {
            if (!importable.fileId) {
              return fileService
                .uploadFlowModelSource(importable.file)
                .then((fileResponse) => {
                  importable.fileId = fileResponse.data;
                  return workflowResponse.data?.Id;
                });
            }
            return workflowResponse.data?.Id;
          })
          .then((flowModelId: Guid) => {
            if (!flowModelId || flowModelId === guidService.empty()) {
              return;
            }
            return flowModelApiService
              .linkFlowModelToSourceFile(flowModelId, importable.fileId)
              .then(() => flowModelId);
          })
          .then((flowModelId) => {
            if (!flowModelId || !importable.publish) {
              return;
            }
            const publishDetails: IPublishWorkflowModel = {
              publishToEveryone: true,
              flowId: flowModelId,
              categoryId: importable.categoryId,
              publishType: flowinglyConstants.flowModelPublishType.PROCESS_MAP,
              notifyUsers: false,
              isConfidential: false
            };
            return flowModelApiService.publishFlowModel(publishDetails);
          });
      });
  }
}

export type FlowModelImportServiceType = ReturnType<
  typeof flowModelImportService
>;
