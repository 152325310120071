import React, { useState } from 'react';
import Box from '@mui/material/Box';
import StepDetails from './StepDetails/StepDetails';
import StepForm from './StepForm/StepForm';
import Header from './Header/Header';
import { StepData } from './StepDetails/StepDetails';
import CustomTabPanel from '../CustomTab/CustomTabPanel';
import CustomTabs from '../CustomTab/CustomTabs';

type Props = {
  selectedNode: { stepName: string; stepActor: string };
};

const RightPanel = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const { selectedNode } = props;

  const stepName = selectedNode && selectedNode.stepName;
  const stepAssignee = selectedNode && selectedNode.stepActor;

  const handleChange = (_event: Event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box width={'100%'} padding={'2px'}>
      <Header stepName={stepName} stepAssignee={stepAssignee}></Header>
      <CustomTabs
        value={selectedTab}
        onChange={handleChange}
        tabs={[{ label: 'Step Details' }, { label: 'Step Form' }]}
      />
      <CustomTabPanel value={selectedTab} index={0}>
        <StepDetails stepData={selectedNode as unknown as StepData} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <StepForm selectedNode={selectedNode} />
      </CustomTabPanel>
    </Box>
  );
};

export default RightPanel;
