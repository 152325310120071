'use strict';
import angular from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';

///
/// This state selects a report based on its id and then calls up that report component
///

// Register the application's routes configuration.

angular.module('flowingly.runner.report').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider
    .state('app.runner.report.old', {
      url: 'report/{flowModelId}/{flowModelName}?startDate&endDate',
      redirectTo: 'app.runner.report'
    })
    .state('app.runner.report', {
      url: 'report/{flowModelId}?startDate&endDate',
      templateUrl: 'Client/runner.report/runner.report.tmpl.html',
      controller: [
        '$stateParams',
        '$state',
        function ($stateParams: IStateParamsService, $state: IStateService) {
          const ctrl = this;
          ctrl.flow = $stateParams.flowModelId;
          ctrl.endDate = $stateParams.endDate;
          ctrl.startDate = $stateParams.startDate;
        }
      ],
      controllerAs: 'ctrl'
    });
}
