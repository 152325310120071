/**
 * @ngdoc type
 * @name TemplateAccessDialogController
 * @module flowingly.runner.templates
 * @description Controls send template access request modal dialog
 */
'use strict';
import angular from 'angular';
import { Guid } from '../../interfaces/util';
import { SharedAngular } from '@Client/@types/sharedAngular';

export default class TemplateAccessDialogController {
  static $inject = [
    '$scope',
    'templateService',
    'sessionService',
    'notificationService'
  ];

  private managerName: string;
  private templateId: Guid;
  private templateKey: string;
  private templateName: string;
  private message: string;
  private requestPending = false;

  constructor(
    private $scope: IScopeNgDialog,
    private templateService: SharedAngular.TemplateService,
    private sessionService: SharedAngular.SessionService,
    private notificationService: SharedAngular.NotificationService
  ) {
    this.managerName = this.sessionService.getUser().managerName;
    this.templateId = $scope.ngDialogData.templateId;
    this.templateKey = $scope.ngDialogData.templateKey;
    this.templateName = $scope.ngDialogData.templateName;
    this.message = '';
  }

  private send = () => {
    this.requestPending = true;
    const accessRequest = {
      templateId: this.templateId,
      templateKey: this.templateKey,
      templateName: this.templateName,
      message: this.message
    };

    this.templateService
      .sendAccessRequest(accessRequest)
      .then(() => {
        this.notificationService.showSuccessToast(
          'Request has been successfully sent'
        );
        this.$scope.closeThisDialog();
      })
      .catch(() => {
        this.requestPending = false;
        this.notificationService.showErrorToast(
          'An error occurred while sending request'
        );
      });
  };

  private cancel = () => {
    this.$scope.closeThisDialog();
  };
}

angular
  .module('flowingly.runner.templates')
  .controller('templateAccessDialogController', TemplateAccessDialogController);
