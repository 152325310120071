/**
 * @ngdoc directive
 * @name usersListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a users row
 * @usage
 * ```
     <users-list-actions user-id='dataItem.id'></users-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * userId: id of user to act upon
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';

angular
  .module('flowingly.runner.maintenance')
  .component('maintenanceUserListActions', {
    bindings: {
      user: '<',
      onUserUpdated: '&'
    },
    templateUrl:
      'Client/runner.maintenance/users/runner.maintenance.user.list.actions.tmpl.html',
    controller: [
      '$scope',
      'dialogService',
      'permissionsService',
      'flowinglyConstants',
      function (
        $scope: IScope,
        dialogService: SharedAngular.DialogService,
        permissionsService: SharedAngular.PermissionsService,
        flowinglyConstants: SharedAngular.FlowinglyConstants
      ) {
        const $ctrl = this;

        $ctrl.editUser = editUser;
        $ctrl.hasActionsPermission =
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.ADMINISTRATION_ACTIONS
          );

        function editUser() {
          dialogService
            .showDialog({
              template:
                'Client/runner.maintenance/users/runner.maintenance.user.detail.tmpl.html',
              controller: 'maintenanceUserDetailController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: $ctrl.user
            })
            .then(function (result) {
              if (
                result === false ||
                (result !== undefined &&
                  dialogService.isCloseModalWithCancelAction(result))
              ) {
                return;
              }
              $ctrl.onUserUpdated();
            });
        }
      }
    ]
  });
