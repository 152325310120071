/**
 * @ngdoc directive
 * @name libraryStatusBadge
 * @module flowingly.runner.directives
 * @description A simple directive for displaying an (approporiate) empty state message.
 * @usage
 * ```
 *   <flow-nothing-todo-message items="ctrl.flows"></flow-nothing-todo-message>
 * ```
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.library').component('libraryStatusBadge', {
  bindings: {
    status: '<'
  },
  controller: [
    'APP_CONFIG',
    function (APP_CONFIG) {
      const $ctrl = this;

      $ctrl.$onChanges = function (changes) {
        if (changes.status) {
          if (
            $ctrl.status === 'Published Public' &&
            !APP_CONFIG.enablePublicProcessMaps
          ) {
            $ctrl.status = 'Published';
          }
        }
      };

      $ctrl.getStatusDisplay = function () {
        switch ($ctrl.status) {
          case 'Draft':
            return 'val-badge--draft';
          case 'Published':
            return 'val-badge--published';
          case 'Published Public':
            return 'val-badge--published-public';
          default:
            return '';
        }
      };
    }
  ],
  template: `
    <span class="val-badge" ng-class="$ctrl.getStatusDisplay()">{{$ctrl.status}}</span>
  `
});
