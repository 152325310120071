'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';

interface IInviteUser {
  firstName: string;
  lastName: string;
  email: string;
}

class UserAddController {
  static $inject = [
    'ngDialog',
    'userApiService',
    'notificationService',
    'validationService',
    'importErrorService',
    'sessionService'
  ];

  public emailValidationPattern: RegExp;
  public users: IInviteUser[];
  public errorOnSubmit = false;

  constructor(
    private ngDialog,
    private userApiService,
    private notificationService,
    private validationService,
    private importErrorService: ImportErrorService,
    private sessionService: SharedAngular.SessionService
  ) {
    this.emailValidationPattern =
      this.validationService.getEmailValidationPattern();
    this.users = this.createInitialUserList();
  }

  public addNewUser() {
    this.users.push(this.getNewUser());
  }

  public closeDialog() {
    this.ngDialog.closeAll();
  }

  public deleteClick(index) {
    this.users.splice(index, 1);
  }

  public noUserEntered(): boolean {
    return !this.users.some(
      (u) => u.firstName !== '' || u.lastName !== '' || u.email !== ''
    );
  }

  public submitChanges() {
    if (this.noUserEntered()) {
      this.errorOnSubmit = true;
      return;
    }
    this.addUsers();
  }

  private addUsers() {
    const userImportRequest = this.users
      .filter(
        (user) =>
          user.firstName !== '' && user.lastName !== '' && user.email !== ''
      )
      .map((row) => {
        return {
          user: {
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email
          },
          manager: {},
          connectionType: 0
        };
      });

    this.userApiService.addUsers(userImportRequest).then((response) => {
      this.ngDialog.closeAll(true);
      const successCount = response.data.filter(
        (row) => row.requestStatus == this.importErrorService.ROW_STATUS.DONE
      ).length;

      const businessName = this.sessionService.getBusinessName();
      if (successCount === 0) {
        this.notificationService.showWarningToast(
          `No user(s) added to ${businessName}`
        );
      } else {
        this.notificationService.showSuccessToast(
          `${successCount} user(s) successfully invited to ${businessName}`
        );
      }
    });
  }

  private getNewUser(): IInviteUser {
    return {
      firstName: '',
      lastName: '',
      email: ''
    };
  }

  private createInitialUserList(): IInviteUser[] {
    return [this.getNewUser(), this.getNewUser(), this.getNewUser()];
  }
}

angular
  .module('flowingly.runner.setup')
  .controller('userAddController', UserAddController);
