import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { ImportError } from './runner.import.error.service';

class ImportErrorController {
  static $inject = [
    'runnerImportService',
    'validationService',
    'guidService',
    'importErrorService',
    '$scope'
  ];
  private errors = {
    email: {
      issue: 'Email is invalid',
      affectedRows: '',
      howToFix: 'Please provide your user/manager email in a valid format'
    },
    name: {
      issue: 'First name or last name is missing',
      affectedRows: '',
      howToFix: 'Please provide first name & last name for each user'
    },
    nameXssVulnerable: {
      issue: 'First name or last name does not support HTML',
      affectedRows: '',
      howToFix: 'Please provide valid first name & last name for each user'
    },
    header: {
      issue: 'Missing column headers',
      affectedRows: '1',
      howToFix:
        'Please add first_name, last_name & email as column headers to row 1 in your CSV file',
      showError: false
    },
    customDbId: {
      issue: 'Id is not valid GUID',
      affectedRows: '',
      howToFix:
        'Please provide valid Id or leave it empty for insert new record for each row'
    },
    customeDbDeleteFlag: {
      issue: 'Invalid delete flag',
      affectedRows: '',
      howToFix:
        'Please use yes for column delete_record for each row wish to delete'
    },
    customeDbDeleteFlagWithEmptyId: {
      issue: 'You cannot delete an empty row',
      affectedRows: '',
      howToFix: 'Please ensure that all rows you wish to delete contain data'
    },
    customeDbColumnType: {
      issue: 'Wrong data type',
      affectedRows: '',
      howToFix: 'Please input number for number/currency column'
    },
    emptyRow: {
      issue: 'Empty row',
      affectedRows: '',
      howToFix: 'Please input some data'
    },
    errorMessage: '',
    hasError: true
  };
  private fileHasError;
  private onParseSuccess;
  private isImportUser;
  private localFile;
  private columns;

  constructor(
    private runnerImportService: RunnerImportService,
    private validationService: SharedAngular.ValidationService,
    private guidService: SharedAngular.GuidService,
    private importErrorService: ImportErrorService,
    private $scope: IScope
  ) {
    this.importErrorService = importErrorService;
  }

  $onChanges(changes) {
    this.importErrorService.setEmpty();

    // reset to default value
    this.errors.email.affectedRows = '';
    this.errors.name.affectedRows = '';
    this.errors.nameXssVulnerable.affectedRows = '';
    this.errors.customDbId.affectedRows = '';
    this.errors.customeDbDeleteFlag.affectedRows = '';
    this.errors.customeDbDeleteFlagWithEmptyId.affectedRows = '';
    this.errors.customeDbColumnType.affectedRows = '';
    this.errors.emptyRow.affectedRows = '';
    this.errors.header.showError = false;
    this.errors.errorMessage = '';
    this.errors.hasError = false;

    if (changes.file && changes.file.currentValue) {
      // got new file, parse it and report if any errors
      if (this.runnerImportService.isCsvFile(changes.file.currentValue.name)) {
        this.localFile = changes.file.currentValue;
        this.runnerImportService.parseCsvFile(
          this.localFile,
          this.onParseComplete.bind(this),
          this.onParseError.bind(this)
        );
      }
    }
  }

  private onParseComplete(results) {
    if (this.isImportUser) {
      this.validateUsersImport(results);
    } else {
      this.validateDbImport(results);
    }

    if (
      this.errors.name.affectedRows !== '' ||
      this.errors.nameXssVulnerable.affectedRows !== '' ||
      this.errors.email.affectedRows !== '' ||
      this.errors.customDbId.affectedRows !== '' ||
      this.errors.customeDbDeleteFlag.affectedRows !== '' ||
      this.errors.customeDbDeleteFlagWithEmptyId.affectedRows !== '' ||
      this.errors.customeDbColumnType.affectedRows !== '' ||
      this.errors.emptyRow.affectedRows !== '' ||
      this.errors.header.showError ||
      this.importErrorService.hasErrors()
    ) {
      this.errors.hasError = true;
      this.fileHasError();
    } else {
      this.onParseSuccess({ data: results.data });
    }
  }

  private validateDbImport(results) {
    const idErrorLines = [];
    const deleteErrorLines = [];
    const invalidDeleteErrorLines = [];
    const columnTypeErrorLines = [];
    const emptyRowErrorLines = [];
    const colHeaders = [];
    this.columns.forEach((col) => colHeaders.push(col.name));

    if (
      !(
        results.meta.fields &&
        results.meta.fields.length === this.columns.length &&
        results.meta.fields.join() === colHeaders.join()
      )
    ) {
      this.errors.header.issue = 'Invalid column headers';
      this.errors.header.howToFix = `Please make sure that the header row in the CSV file is in the order: ${colHeaders
        .join()
        .replace(/,/g, ', ')}`;
      this.errors.header.showError = true;
    } else {
      // validate data
      results.data.forEach((row, idx) => {
        if (this.columns.every((col) => row[col.name].trim() === '')) {
          emptyRowErrorLines.push(idx + 2); // need count header row
        }

        if (
          row['customdatabaseid'] &&
          !this.guidService.isValidGuid(row['customdatabaseid'])
        ) {
          idErrorLines.push(idx + 2); // need count header row
        }

        if (!row['customdatabaseid'] && row['delete_record']) {
          invalidDeleteErrorLines.push(idx + 2); // id is empty and delete_record field is not empty
        }

        if (
          row['delete_record'] &&
          row['delete_record'].toLowerCase() !== 'yes'
        ) {
          deleteErrorLines.push(idx + 2); // need count header row
        }

        let invalidColType = false;
        this.columns
          .filter((col) => col.isNumberField)
          .forEach((col) => {
            if (
              row[col.name] &&
              row[col.name].trim() !== '' &&
              isNaN(row[col.name].trim())
            )
              invalidColType = true;
          });
        if (invalidColType) {
          columnTypeErrorLines.push(idx + 2); // need count header row
        }
      });
    }

    if (idErrorLines.length > 0) {
      this.errors.customDbId.affectedRows = idErrorLines.join(',');
    }

    if (invalidDeleteErrorLines.length > 0) {
      this.errors.customeDbDeleteFlagWithEmptyId.affectedRows =
        invalidDeleteErrorLines.join(',');
    }

    if (deleteErrorLines.length > 0) {
      this.errors.customeDbDeleteFlag.affectedRows = deleteErrorLines.join(',');
    }

    if (columnTypeErrorLines.length > 0) {
      this.errors.customeDbColumnType.affectedRows =
        columnTypeErrorLines.join(',');
    }

    if (emptyRowErrorLines.length > 0) {
      this.errors.emptyRow.affectedRows = emptyRowErrorLines.join(',');
    }
  }

  private ACCEPTED_FIELDS = [
    'first_name',
    'last_name',
    'email',
    'manager_email',
    'connection_type'
  ];

  private validateUsersImport(results) {
    const nameErrorLines = [];
    const nameXssErrorLines = [];
    const emailErrorLines = [];

    const hasBadHeader = results.meta.fields.find((field) => {
      return !this.ACCEPTED_FIELDS.find((col) => col == field);
    });

    if (hasBadHeader) {
      this.importErrorService.addError(
        new ImportError({
          issueId: this.importErrorService.ROW_STATUS.FAILED_BAD_HEADER,
          affectedRows: [1]
        })
      );
    }

    if (
      !(
        results.meta.fields &&
        results.meta.fields.length >= 3 &&
        results.meta.fields[0] === 'first_name' &&
        results.meta.fields[1] === 'last_name' &&
        results.meta.fields[2] === 'email'
      )
    ) {
      this.errors.header.showError = true;
    } else {
      // validate data
      results.data.forEach((u, idx) => {
        const rowNum = idx + 2; // need count header row

        if (
          u.connection_type &&
          u.connection_type != '' &&
          u.connection_type != 'sso' &&
          u.connection_type != 'password'
        ) {
          this.importErrorService.addError(
            new ImportError({
              issueId:
                this.importErrorService.ROW_STATUS.FAILED_BAD_CONNECTION_TYPE,
              affectedRows: [rowNum]
            })
          );
        }

        if (
          u.manager_email &&
          !this.runnerImportService.isCorrectEmailFormat(u.manager_email)
        ) {
          emailErrorLines.push(rowNum);
        }

        if (
          !this.runnerImportService.stringIsNotEmpty(u.first_name) ||
          !this.runnerImportService.stringIsNotEmpty(u.last_name)
        ) {
          nameErrorLines.push(rowNum);
        }

        if (
          this.validationService.isXssVulnerableString(u.first_name) ||
          this.validationService.isXssVulnerableString(u.last_name)
        ) {
          nameXssErrorLines.push(rowNum);
        }

        if (
          !this.runnerImportService.stringIsNotEmpty(u.email) ||
          !this.runnerImportService.isCorrectEmailFormat(u.email)
        ) {
          emailErrorLines.push(rowNum);
        }
      });
    }

    if (nameErrorLines.length > 0) {
      this.errors.name.affectedRows = nameErrorLines.join(',');
    }

    if (nameXssErrorLines.length > 0) {
      this.errors.nameXssVulnerable.affectedRows = nameXssErrorLines.join(',');
    }

    if (emailErrorLines.length > 0) {
      this.errors.email.affectedRows = emailErrorLines.join(',');
    }
  }

  private onParseError(error) {
    this.errors.errorMessage = error.message;
    this.errors.hasError = true;
    this.fileHasError();
  }
}

class RunnerImportError implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      file: '<',
      isImportUser: '<',
      columns: '<',
      fileHasError: '&',
      onParseSuccess: '&'
    };

    this.templateUrl = 'Client/runner.import/runner.import.error.tmpl.html';
  }

  controller = ImportErrorController;
}

angular
  .module('flowingly.runner.import')
  .component('runnerImportError', new RunnerImportError());

export type RunnerImportErrorType = InstanceType<typeof RunnerImportError>;
