import React, { useEffect, useState } from 'react';
import { useService } from '@Client/runner.hooks/useService';

export type StepData = {
  reminder: string;
  deadLine: string;
  costMoney: string;
  notificationRequired: boolean;
  plannedTime: string;
};

type Props = {
  stepData: StepData;
};

const StepDetails = (props: Props) => {
  const { stepData } = props;
  const appConfig = useService<AppConfig>('APP_CONFIG');

  let stepTimeCostVisibility: string;
  const NONE = 'None';
  const NOT_SPECIFIED = 'Not specified by Process Owner';

  const [derivedStepData, setDerivedStepData] = useState<StepData | null>(null);

  useEffect(() => {
    if (stepData == null) {
      setDerivedStepData(null);
      return;
    }

    const updatedStepData: StepData = {
      reminder: stepData.reminder ?? '',
      deadLine: stepData.deadLine ?? NONE,
      costMoney: stepData.costMoney ?? NOT_SPECIFIED,
      notificationRequired: stepData.notificationRequired ?? false,
      plannedTime: stepData.plannedTime ?? NOT_SPECIFIED
    };

    stepTimeCostVisibility = appConfig.stepTimeCostVisibility;

    setDerivedStepData(updatedStepData);
  }, [stepData]);

  const isStepTimeCostVisible = (type: string) => {
    if (stepTimeCostVisibility == null) return true;
    else {
      if (type === 'time')
        return (
          stepTimeCostVisibility.toLowerCase() === 'both' ||
          stepTimeCostVisibility.toLowerCase() === 'timeonly'
        );
      if (type === 'cost')
        return (
          stepTimeCostVisibility.toLowerCase() === 'both' ||
          stepTimeCostVisibility.toLowerCase() === 'costonly'
        );
    }
  };

  const isStepTimeVisible = isStepTimeCostVisible('time');
  const isStepCostVisible = isStepTimeCostVisible('cost');

  if (derivedStepData === null) {
    return null;
  }

  return (
    <div className="pmap-step-details-tab">
      <div>
        <label className="row-title">Step Reminder</label>
      </div>
      <div>
        <p>
          <span>{derivedStepData.reminder}</span>
        </p>
      </div>
      <div>
        <label className="row-title">Step Deadline</label>
      </div>
      <div>
        <p>
          <span>{derivedStepData.deadLine}</span>
        </p>
      </div>
      {isStepTimeVisible && (
        <div>
          <label className="row-title">Planned Time</label>
        </div>
      )}
      {isStepTimeVisible && (
        <div>
          <p>
            <span
              className={
                derivedStepData.plannedTime === NOT_SPECIFIED
                  ? 'process-map-v2-view-step-details-italic-and-grayed'
                  : ''
              }
            >
              {derivedStepData.plannedTime}
            </span>
          </p>
        </div>
      )}

      {isStepCostVisible && (
        <div>
          <label className="row-title">Planned Cost</label>
        </div>
      )}

      {isStepCostVisible && (
        <div>
          <p>
            {derivedStepData.costMoney === NOT_SPECIFIED ? (
              <span
                className={'process-map-v2-view-step-details-italic-and-grayed'}
              >
                {derivedStepData.costMoney}
              </span>
            ) : (
              <span>
                {parseFloat(derivedStepData.costMoney).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </span>
            )}
          </p>
        </div>
      )}

      <div>
        <label className="row-title">Step Notification</label>
      </div>

      <div>
        <input
          id="step-details-notify"
          type="checkbox"
          disabled
          checked={derivedStepData.notificationRequired}
        />
        <label
          className={`not-clickable fg-checkbox-tick ${
            !derivedStepData.notificationRequired
              ? 'process-map-v2-view-step-details-grayed'
              : ''
          }`}
          htmlFor="step-details-notify"
        >
          Notify initiator when this step has been completed
        </label>
      </div>
    </div>
  );
};

export default StepDetails;
