import { Box } from '@mui/material';
import React, { useRef } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import CopyTooltip from './CopyTooltip/CopyTooltip';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';

type Props = {
  closeModal: () => void;
  isPublicMap: boolean;
  currentProcessMapId: string;
};

const ShareModal = (props: Props) => {
  const { closeModal, isPublicMap, currentProcessMapId } = props;
  const shareWithAColleagueTextboxRef = useRef();
  const shareAsPublicMapTextboxRef = useRef();
  const iframeShareableUrlTextboxRef = useRef();

  const shareWithAColleagueTooltipRef = useRef();
  const shareAsPublicMapTooltipRef = useRef();
  const iframeShareableUrlTooltipRef = useRef();

  const mapRoute = 'processmapsv2';
  const mapUrl = `${window.location.origin}/${mapRoute}/${currentProcessMapId}`;
  const publicMapUrl = mapUrl.replace(mapRoute, 'map');
  const iframeUrl = `<iframe width="650" height="800" src="${publicMapUrl}" frameborder="0"></iframe>`;

  const defaultTextToDisplay =
    'Public sharing is not enabled for this process map.';

  const { enablePublicProcessMaps } =
    useService<SharedAngular.APP_CONFIG>('APP_CONFIG');

  const copyToClipboard = (ref, tooltipRef) => {
    if (
      (ref === iframeShareableUrlTextboxRef ||
        ref === shareAsPublicMapTextboxRef) &&
      (!isPublicMap || !enablePublicProcessMaps)
    )
      return;
    navigator.clipboard.writeText(ref.current.innerText);
    showCopiedTooltip(tooltipRef);
  };

  const showCopiedTooltip = (ref) => {
    const element = ref.current;
    if (ref) {
      element.classList.add('tooltiptext--show');
      setTimeout(() => {
        element.classList.remove('tooltiptext--show');
      }, 1000);
    }
  };

  return (
    <Box className="share-modal-container">
      <p className="share-modal-heading">Share Process Map</p>
      <CopyTooltip
        disabled={false}
        label="Share With a Colleague"
        textareaId="shareWithColleague"
        textareaValue={mapUrl}
        textareaRef={shareWithAColleagueTextboxRef}
        tooltipRef={shareWithAColleagueTooltipRef}
        onClick={() =>
          copyToClipboard(
            shareWithAColleagueTextboxRef,
            shareWithAColleagueTooltipRef
          )
        }
      />
      <div>
        <CopyTooltip
          label="Public Link"
          textareaId="shareAsPublicMap"
          textareaValue={
            isPublicMap && enablePublicProcessMaps
              ? publicMapUrl
              : defaultTextToDisplay
          }
          textareaRef={shareAsPublicMapTextboxRef}
          tooltipRef={shareAsPublicMapTooltipRef}
          disabled={!isPublicMap || !enablePublicProcessMaps}
          onClick={() =>
            copyToClipboard(
              shareAsPublicMapTextboxRef,
              shareAsPublicMapTooltipRef
            )
          }
        />
        <CopyTooltip
          label="Embed Code"
          textareaId="embeddedIframeUrl"
          textareaValue={
            isPublicMap && enablePublicProcessMaps
              ? iframeUrl
              : defaultTextToDisplay
          }
          textareaRef={iframeShareableUrlTextboxRef}
          tooltipRef={iframeShareableUrlTooltipRef}
          disabled={!isPublicMap || !enablePublicProcessMaps}
          onClick={() =>
            copyToClipboard(
              iframeShareableUrlTextboxRef,
              iframeShareableUrlTooltipRef
            )
          }
        />
      </div>

      <div className="share-modal-done-button">
        <CustomButton buttonText="Done" onClick={closeModal} />
      </div>
    </Box>
  );
};

export default ShareModal;
