import React, { useCallback, useEffect, useState } from 'react';
import LeftPanel from './LeftPanel/LeftPanel';
import RightPanel from './RightPanel/RightPanel';
import { Box, Drawer } from '@mui/material';
import BreadcrumbsNavigator from './BreadcrumbsNavigator/BreadcrumbsNavigator';
import { BreadCrumbType } from '@Client/runner.processmapsv2/@types/breadcrumb';
import ProcessMapType from '@Client/runner.processmapsv2/@types/processMap';
import CustomButton from '../View/LeftPanel/CustomButton/CustomButton';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';

type Props = {
  processMapId: string;
  hideFeedBack: boolean;
  hideFlowModelOpenButton: boolean;
  hideBackToFlowButton: boolean;
};

const ProcessMapView = (props: Props) => {
  const { processMapId: parentProcessMapId, hideFeedBack, hideFlowModelOpenButton, hideBackToFlowButton } = props;
  const [open, setOpen] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(500);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [currentBreadCrumb, setCurrentBreadCrumb] =
    useState<BreadCrumbType>(null);
  const [currentProcessMapId, setCurrentProcessMapId] = useState<string>(null);
  const [breadcrumbCount, setBreadcrumbCount] = useState<number>(0);
  const [processMapHeight, setProcessMapHeight] = useState(
    window.innerHeight - 275
  );
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');

  useEffect(() => {
    updateProcessMapId(parentProcessMapId);
  }, [parentProcessMapId]);

  useEffect(() => {
    if (currentBreadCrumb && breadcrumbCount === 0) {
      setProcessMapHeight(window.innerHeight - 275);
      updateBreadcrumbCount(1); // reset breadcrumb count
    }
  }, [breadcrumbCount]);

  const handleSelectedNodeDataChange = (node: unknown) => {
    setSelectedNodeData(node);
    if (node == null) {
      toggleDrawer(false);
    } else {
      toggleDrawer(true);
    }
  };

  const getCurrentProcessMap = (processMap: ProcessMapType) => {
    if (!processMap) {
      setErrorPage(true);
      return;
    }

    const breadCrumb: BreadCrumbType = {
      id: processMap.flowId,
      name: processMap.name,
      isParent: parentProcessMapId === processMap.flowId
    };
    setCurrentBreadCrumb(breadCrumb);
    updateProcessMapId(processMap.flowId);

    if (breadcrumbCount > 0) {
      setProcessMapHeight(window.innerHeight - (275 + 50));
    } else {
      setProcessMapHeight(window.innerHeight - 275);
      updateBreadcrumbCount(1); // reset breadcrumb count
    }
  };

  const updateProcessMapId = (mapId: string) => {
    handleSelectedNodeDataChange(null);
    setCurrentProcessMapId(mapId);
  };

  const updateBreadcrumbCount = (count: number) => {
    setBreadcrumbCount(count);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    setIsDragging(false);
  };

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const maxDrawerWidth = window.innerWidth / 1.5;
    const minDrawerWidth = 25;
    const newWidth = Math.min(
      maxDrawerWidth,
      Math.max(minDrawerWidth, window.innerWidth - e.clientX)
    );
    setDrawerWidth(newWidth);
  }, []);

  function toggleDrawer(open: boolean): void {
    setOpen(open);
  }
  const onBackButtonClick = () => {
    pubSubService.publish('BACKTOFLOWS_IMIN');
  };

  return (
    <Box>
      {errorPage && hideBackToFlowButton ? (
        <Box className="align-center pb-30 pt-50">
          <Box className="mt-50">
            <i className="fa-regular fa-circle-exclamation process-map-v2-view-error-icon" />
            <h5>
              Permission Denied!
              <br /> <br /> <br />
              You don't have permissions to access this page. <br /> <br />
              Contact a Business Admin to get permissions
            </h5>
            <br />
          </Box>
        </Box>
      ) : errorPage && !hideBackToFlowButton ? (
        <Box className="align-center pb-30 pt-50">
          <Box className="mt-50">
            <i className="fa-regular fa-circle-exclamation process-map-v2-view-error-icon" />
            <h5>
              Permission Denied!
              <br /> <br /> <br />
              You don't have permissions to access this page. <br />
              <br />
              Contact a Business Admin to get permissions or go to Flows I'm in <br /> <br />
              <CustomButton
                buttonText="Back to Flows I'm In"
                onClick={onBackButtonClick}
              />
            </h5>
            <br />
          </Box>
        </Box>
      ) : (
        <Box className={'process-map-v2-view-container'}>
          <div>
            <BreadcrumbsNavigator
              currentBreadCrumb={currentBreadCrumb}
              updateBreadcrumbCount={updateBreadcrumbCount}
              handleClick={updateProcessMapId}
            ></BreadcrumbsNavigator>
          </div>
          <Box className="process-map-wrapper">
            {currentProcessMapId && (
              <LeftPanel
                hideFeedBack={hideFeedBack}
                hideFlowModelOpenButton={hideFlowModelOpenButton}
                processMapHeight={processMapHeight}
                processMapId={currentProcessMapId}
                onProcessMapRetrieved={getCurrentProcessMap}
                onSelectedNodeDataChange={handleSelectedNodeDataChange}
              />
            )}

            <Drawer
              variant="persistent"
              anchor="right"
              hideBackdrop={true}
              open={open}
              PaperProps={{
                className: 'process-map-v2-view-drawer-paper',
                sx: {
                  width: drawerWidth
                }
              }}
            >
              <Box
                onMouseDown={(e) => handleMouseDown(e)}
                className={`${'process-map-v2-view-resize-handle'} ${
                  isDragging
                    ? 'process-map-v2-view-resize-handle-is-dragging'
                    : ''
                }`}
              >
                <div className={'process-map-v2-view-resize-handle-icon'}>
                  <i className="dragger-icon-color fa-light fa-arrows-left-right fa-3x"></i>
                </div>
              </Box>
              <Box flexGrow={1} overflow={'auto'}>
                <RightPanel selectedNode={selectedNodeData} />
              </Box>
            </Drawer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProcessMapView;
