/**
 * @ngdoc service
 * @name flowModelOwnerDialogService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for flow model owner dialog
 *
 *
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import IFlowinglySearchResult from '@Shared.Angular/@types/core/contracts/queryModel/search/flowinglySearchResult';
import angular, { IHttpService } from 'angular';

angular
  .module('flowingly.runner.library')
  .factory('flowModelOwnerDialogService', flowModelOwnerDialogService);

flowModelOwnerDialogService.$inject = ['$http', 'APP_CONFIG'];

function flowModelOwnerDialogService(
  $http: IHttpService,
  APP_CONFIG: SharedAngular.APP_CONFIG
) {
  const service = {
    getExistingFlowModelOwners: getExistingFlowModelOwners,
    getExistingReportOwners: getExistingReportOwners,
    saveFlowModelOwners: saveFlowModelOwners,
    saveReportOwners: saveReportOwners,
    modifyPublicMapSharing: modifyPublicMapSharing
  };

  return service;

  function saveFlowModelOwners(model) {
    return $http.post(
      APP_CONFIG.apiBaseUrl + 'workflow/saveFlowModelOwners',
      model
    );
  }

  function saveReportOwners(model) {
    return $http.post(
      APP_CONFIG.apiBaseUrl + 'workflow/saveReportOwners',
      model
    );
  }

  function getExistingFlowModelOwners(flowModelId) {
    return $http.get<IResponseData<IFlowinglySearchResult[]>>(
      APP_CONFIG.apiBaseUrl +
        'workflow/getFlowModelOwners?flowModelId=' +
        flowModelId
    );
  }

  function getExistingReportOwners(flowModelId) {
    return $http.get<IResponseData>(
      APP_CONFIG.apiBaseUrl +
        'workflow/getReportOwners?flowModelId=' +
        flowModelId
    );
  }

  function modifyPublicMapSharing(flowModelId, isMapPubliclyShareable) {
    return $http.put<IResponseData>(
      APP_CONFIG.apiBaseUrl +
        'workflow/' +
        flowModelId +
        '/modifyPublicMapSharing',
      isMapPubliclyShareable
    );
  }
}

export type FlowModelOwnerDialogServiceType = ReturnType<
  typeof flowModelOwnerDialogService
>;
