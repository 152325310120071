/**
 * @ngdoc directive
 * @name flowLibraryHeader
 * @module flowingly.runner.library
 * @description  This comppnent is used to display the header for the flow library and will contain controls
 * @usage
 * ```
      <flow-library-header></flow-library-header>
 * ``` 
 */
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.library').component('flowLibraryHeader', {
  bindings: {
    canCreateFlows: '<'
  },
  templateUrl: 'Client/runner.library/runner.library.header.tmpl.html',
  controller: [
    'tempModelerUrlService',
    '$state',
    'APP_CONFIG',
    'appInsightsService',
    'dialogService',
    'permissionsService',
    'flowinglyConstants',
    function (
      tempModelerUrlService: SharedAngular.TempModelerUrlService,
      $state: IStateService,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      appInsightsService: SharedAngular.AppInsightsService,
      dialogService: SharedAngular.DialogService,
      permissionsService: SharedAngular.PermissionsService,
      flowinglyConstants: SharedAngular.FlowinglyConstants
    ) {
      this.templateLibraryEnabled = APP_CONFIG.enableTemplateLibrary;
      this.showImportButton =
        APP_CONFIG.enableBulkFlowModelImport &&
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.FLOWMODEL_BULKIMPORT
        );

      this.onAddFlowModelClick = () => {
        tempModelerUrlService.openModeler();
      };

      this.viewTemplatesClicked = () => {
        $state.go('app.runner.templates.library');
        appInsightsService.trackPageView('template-library', {
          from: 'library'
        });
      };

      this.importFlowModelsClicked = () => {
        dialogService.showDialog({
          template:
            'Client/runner.import/flowmodels/runner.import.flowmodels.dialog.tmpl.html',
          controller: 'importFlowModelsDialogController',
          appendClassName: 'ngdialog-normal'
        });
      };
    }
  ]
});
