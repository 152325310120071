/**
 * @ngdoc service
 * @name authService
 * @module flowingly.runner.services
 *
 * @description A helper service for authenticating the user.
 *
 * ## Notes
 *
 * ###API
 * * userProfile - retunrs the users stored profile
 * * login - log the user in
 * * logout - log the user out
 * * registerAuthenticationListener - register callback for Auth0 login
 *
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import { IFlowinglyWindow } from '@Client/interfaces/flowingly.window';
import IUserTokenResponse from '@Shared.Angular/@types/core/contracts/queryModel/api/userTokenResponse';
import IUserDetail from '@Shared.Angular/@types/core/contracts/queryModel/user/userDetail';
import {
  LoginAuditAction,
  LoginAuditIdentityProvider
} from '@Shared.Angular/flowingly.services/flowingly.constants';
import { ILocalUserDetail } from '@Shared.Angular/flowingly.services/sessionService';
import angular, {
  IHttpService,
  IQService,
  IRootScopeService,
  IWindowService
} from 'angular';
import { IStateService } from 'angular-ui-router';
import { Auth0UserProfile } from 'auth0-js';

type LoginOptions = {
  auth0UseUniversalLogin: boolean;
  logoUrl: string;
  welcomeText: string;
  loginAlertMessage: string;
  favIcon: string;
  redirectUrl?: string;
  runnerTitle: string;
};

export interface BusinessTenant {
  id: string;
  name: string;
}

declare const window: IFlowinglyWindow;

angular.module('flowingly.runner.services').factory('authService', authService);

authService.$inject = [
  '$rootScope',
  '$q',
  '$http',
  '$window',
  'dialogService',
  'sessionService',
  'tokenService',
  'APP_CONFIG',
  'sideMenuService',
  'intercomService',
  'lock',
  'angularAuth0',
  'authManager',
  '$state',
  'browserUtilsService',
  'userApiService',
  'lodashService',
  'authLoggingApiService',
  'angularAuth0SpaSdk'
];

function authService(
  $rootScope: IRootScopeService,
  $q: IQService,
  $http: IHttpService,
  $window: IWindowService,
  dialogService: SharedAngular.DialogService,
  sessionService: SharedAngular.SessionService,
  tokenService: SharedAngular.TokenService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  sideMenuService: SideMenuService,
  intercomService: SharedAngular.IntercomService,
  lock: AngularLock,
  angularAuth0: auth0.WebAuth,
  authManager: angular.jwt.IAuthManagerServiceProvider,
  $state: IStateService,
  browserUtilsService: SharedAngular.BrowserUtilsService,
  userApiService: SharedAngular.UserApiService,
  lodashService: Lodash,
  authLoggingApiService: SharedAngular.AuthLoggingApiService,
  angularAuth0SpaSdk: SharedAngular.AngularAuth0SpaSdk
) {
  //See for more details
  //https://github.com/auth0-samples/auth0-angularjs-sample/blob/ui-router-html5/08-Calling-Api/public/components/auth/auth.service.js

  const userProfileJson = sessionService.getProfile();
  let userProfile = (userProfileJson && JSON.parse(userProfileJson)) || null;
  let user = sessionService.getUser();
  let redirectUrl = '';

  const deferredProfile = $q.defer<Auth0UserProfile>();
  let deferredUser = $q.defer<ILocalUserDetail>();

  if (userProfile) {
    deferredProfile.resolve(userProfile);
  }
  if (user) {
    deferredUser.resolve(user);
  }

  const service = {
    getUser: getUser,
    getUserDetails: getUserDetails,
    userProfile: userProfile,
    getProfileDeferred: getProfileDeferred,
    getUserPendingAcceptCondition: getUserPendingAcceptCondition,
    getUserDeferred: getUserDeferred,
    isAuthenticated: isAuthenticated,
    isWorkflowUser: isWorkflowUser,
    login: login,
    logout: logout,
    loginWithToken: loginWithToken,
    setUserAfterSuccessfulLogin: loginSuccessful,
    registerAuthenticationListener: registerAuthenticationListener,
    getTenants: getTenants,
    changeTenant: changeTenant,
    loginFailed: loginFailed,
    auth0SpaSdkAuthenticated: auth0SpaSdkAuthenticated
  };

  return service;

  //////////// Public API Methods

  // When the user logs on he/she is authenticated by Auth0. This happens before
  // we go and retrieve the user details from the API. Once we integrate our User properly
  // with the Auth0 User then this distinctioon will go away (TODO).
  function isAuthenticated() {
    return $rootScope.isAuthenticated;
  }

  function isWorkflowUser() {
    const permissions = sessionService.getPermissions();

    let setupPermission = false;
    lodashService.forEach(permissions, (permission) => {
      if (permission && permission.toLowerCase().indexOf('setup.') > -1) {
        setupPermission = true;
      }
    });

    return !setupPermission;
  }

  async function login(options: LoginOptions) {
    const {
      auth0UseUniversalLogin,
      logoUrl,
      welcomeText,
      loginAlertMessage,
      favIcon,
      redirectUrl,
      runnerTitle
    } = options;

    userProfile = null;
    user = null;
    tokenService.clearToken();
    sessionService.clear();
    authManager.unauthenticate();

    authLoggingApiService.log('Login box is about to be displayed.');

    if (auth0UseUniversalLogin) {
      const params = {
        logo: logoUrl,
        welcomeText: welcomeText,
        loginAlertMessage,
        favIcon,
        state: redirectUrl,
        title: runnerTitle
      };
      if (APP_CONFIG.auth0UseRefreshToken) {
        const snakeCaseParams = lodashService.mapKeys(params, (value, key) =>
          lodashService.snakeCase(key)
        );
        const auth0Client = await angularAuth0SpaSdk.getClient();
        auth0Client.loginWithRedirect({
          authorizationParams: snakeCaseParams,
          appState: { redirectUrl: redirectUrl }
        });
      } else {
        angularAuth0.authorize(params);
      }
    } else {
      // For Auth0 Lock 10 (eg to logon):
      // See Auth0 Lock and doco here: https://github.com/auth0/lock
      // For customisation options => : https://auth0.com/docs/libraries/lock/v10/customization
      // And see https://auth0.com/docs/libraries/lock/v10/sending-authentication-parameters

      // For access to the full autho API (eg to refresh a token):
      // So basically we follow this doco: https://github.com/auth0/auth0.js
      // but we use this library https://github.com/auth0/angular-auth0
      // and we be careful to NOT use auth0-angular as it is for lock 9.

      // And to work with JWTs (eg to see if they have expired):
      // See https://github.com/auth0/angular-jwt

      //setting redirect url only when user is coming from email or report page
      //Though for such urls redirect happens twice, once after auth0 authorization application is redirected
      //and again as request pipeline, auth0 again checks redirected url and finds valid token and shows the page
      //I was trying to stop second redirect because auth0 has already performed authentication during login but
      //couldn't do so as I can't use authManger service to check if use authenticated and $rootscope etc.
      let redirectUrl = '';
      const domainPort =
        window.location.port !== '' ? `:${window.location.port}` : '';
      const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;
      const pushNotifHandle =
        window.isFlowinglyCordova &&
        window.flowinglyCordova.pushNotificationHandle != null &&
        window.flowinglyCordova.pushNotificationHandle
          ? '?pushNotificationHandle=' +
            window.flowinglyCordova.pushNotificationHandle
          : '';

      const currentUrl = window.location.href;
      const splitString = 'redirectUrl=';
      if (
        (currentUrl.indexOf(encodeURIComponent('/flows/')) > 0 ||
          currentUrl.indexOf(encodeURIComponent('/categoryId?')) > 0) &&
        currentUrl.indexOf('access_token=') < 0
      ) {
        const redirectUrlIndex = currentUrl.indexOf(splitString);
        const substringAfterRedirectUrl = currentUrl.substring(
          redirectUrlIndex + splitString.length
        );
        redirectUrl = substringAfterRedirectUrl;
      }

      // Determine if the app is inside an iframe
      const isInIframe = window !== window.parent;
      let callbackRedirectUri = `${currentDomain}/flowsactive/${pushNotifHandle}`;
      if (isInIframe) {
        callbackRedirectUri = `${currentDomain}/iframe/`;
      }

      const authOptions = {
        allowSignUp: false,
        // TODO: FLOW-1214 Disable thid for now to prevent the incorrect user across multiple environments bug
        rememberLastLogin: false,
        avatar: null,
        theme: {
          // TODO: We are using our small logo as the large logo is rendered badly. We could overide the style lock uses for the logo
          // but the auth0 lock doco says clearly that this is not a good thing to do. So, rather we need to create a logo of the right dimensions
          // I believe. Nick has seen an example where a logo such as ours is used. So if we cant get this working then we can ask the question
          // on the auth0 forms.
          logo: APP_CONFIG.logoUrl,
          primaryColor: '#4A90E2'
        },
        closable: false,
        //container: 'hiw-login-container',
        languageDictionary: {
          title: APP_CONFIG.welcomeText, // TODO: We would rather remove the title field all together. I have not worked out how to do that. Will ask on the Auth0 forums.
          usernameOrEmailInputPlaceholder: 'your email'
        },
        sso: true,
        auth: {
          params: {
            scope:
              'openid offline_access nameidentifier businessidentifier flowinglypermissions', //offline_access so that we get a refresh token
            //redirectUri: `${currentDomain}/flowsactive/${window.isFlowinglyCordova ? "?pushNotificationHandle=" + window.flowinglyCordova.pushNotificationHandle : ""}`,
            redirectUri: callbackRedirectUri,
            // state param is returned as is from auth0 service and can be used to send any static data to and fro auth0
            // see more here https://auth0.com/docs/tutorials/redirecting-users
            // though tutorial shows that we can pass object to state but in our case it accepts only string, may be different auth0 APIs
            state: redirectUrl
          }
        },
        _enableIdPInitiatedLogin: true
      };
      lock = new Auth0Lock(
        APP_CONFIG.auth0ClientId,
        APP_CONFIG.auth0Domain,
        authOptions
      );

      lock.show(); //login using Auth0 Lock Login box

      if (loginAlertMessage) {
        setTimeout(() => {
          addAlertMessageToLoginScreen(loginAlertMessage);
        }, 0);
      }
    }
  }

  function logout() {
    authLoggingApiService.log('auth.service logout() called.');
    const userProfileObject = JSON.parse(sessionService.getProfile());

    let loginAuditIdentityProvider = LoginAuditIdentityProvider.UserAndPassword;
    if (sessionService.isSso()) {
      loginAuditIdentityProvider = LoginAuditIdentityProvider.SSO;
    }

    const isADFSUser =
      userProfileObject &&
      userProfileObject.identities &&
      userProfileObject.identities.length > 0 &&
      userProfileObject.identities[0].provider === 'adfs';

    authLoggingApiService.log(
      `auth.service logout() - value for isADFSUser was ${isADFSUser}`
    );

    // log user logout
    return userApiService
      .userLoginAudit({
        success: true,
        identityProvider: loginAuditIdentityProvider,
        action: LoginAuditAction.Logout
      })
      .then(logoutInner)
      .then(function () {
        sideMenuService.clearMenu();

        if (isADFSUser) {
          authLoggingApiService.log(
            `auth.service logout() userLoginAudit().then() - navigating ADFS user to https://"${APP_CONFIG.auth0Domain}/v2/logout?federated`
          );
          $window.location.href =
            'https://' + APP_CONFIG.auth0Domain + '/v2/logout?federated';
        } else {
          authLoggingApiService.log(
            'auth.service logout() userLoginAudit().then() - navigating non-ADFS user to app.login'
          );
          $state.go('app.login');
        }
      });
  }

  async function logoutInner() {
    authLoggingApiService.log('auth.service logout() logoutInner() called.');
    await userApiService.updateLoginState(false);
    deferredUser = $q.defer();
    if (window.isFlowinglyCordova) {
      userApiService.unregisterUserMobileNotification();
    }
    userProfile = null;
    user = null;
    intercomService.shutdown();
    tokenService.clearToken();
    sessionService.clear();
    authManager.unauthenticate();
    deferredUser.resolve();
    return deferredUser.promise;
  }

  function loginWithToken(token: string, profileString: string) {
    tokenService.setToken(token);
    sessionService.setProfile(profileString);
    const profile = JSON.parse(profileString) as Auth0UserProfile;
    deferredProfile.resolve(profile);
    return sessionService
      .getUserDetails(null, token)
      .then(async function (response) {
        if (response === undefined) {
          return Promise.reject('Invalid user');
        }
        await loginSuccessful(response.data.dataModel);
        sessionService.setuserAuthenticated();
      });
  }

  // Set up the logic for when a user authenticates using Auth0 Lock.
  // See https://github.com/auth0/lock for a list of all events that Lock will emit during it's lifecycle
  function registerAuthenticationListener() {
    lock.on('authenticated', function (authResult) {
      // We must even run this script when we are already authenticated as some times we will be re-logging in
      // just because the user is not in local storage and so we need to get the user from the API
      sessionService.setInitialLogin();
      tokenService.setToken(authResult.idToken);
      authManager.authenticate();

      lock.getProfile(authResult.accessToken, function (error, profile) {
        if (error) {
          loginFailed();
          console.log(error);
        }
        // For easy access to the auth0.com flowinglyDB user record data
        sessionService.setProfile(JSON.stringify(profile));
        deferredProfile.resolve(profile);

        if (profile.identities && profile.identities.length > 0) {
          if (
            profile.identities[0].connection ===
            'Username-Password-Authentication'
          ) {
            sessionService.setIsSso(false);
            authLoggingApiService.log(
              `auth.service registerAuthenticationListener() set isSso to false and user profile identity provider was ${profile.identities[0].provider}`
            );
          } else {
            sessionService.setIsSso(true);
            authLoggingApiService.log(
              `auth.service registerAuthenticationListener() set isSso to true and user profile identity provider was ${profile.identities[0].provider}`
            );
          }
        }
      });
      if (authResult.state !== '') {
        redirectUrl = decodeURIComponent(authResult.state);
        authLoggingApiService.log(
          `auth.service registerAuthenticationListener() setting re-dir-ect-url was ${authResult.state}`
        );
      }
    });

    lock.on('authorization_error', function (authResult) {
      if (authResult.description) {
        userApiService.userLoginErrorAudit({
          success: false,
          identityProvider: LoginAuditIdentityProvider.UserAndPassword,
          error: authResult.description,
          token: APP_CONFIG.passCode,
          action: LoginAuditAction.Login
        });
        authLoggingApiService.log(
          `auth.service registerAuthenticationListener() on authorization_error. failed with error ${authResult.description}`
        );
      }
    });
  }

  function getUserPendingAcceptCondition(successCallback, failureCallback) {
    return $http
      .get<IResponseData>(APP_CONFIG.apiBaseUrl + 'usercondition')
      .then((response) => {
        if (response.data && response.data.success && response.data.dataModel) {
          dialogService
            .showDialog({
              template:
                'Client/runner.user-condition/runner.user-condition.dialog.tmpl.html',
              controller: 'userConditionDialogController',
              appendClassName: `ngdialog-normal${
                browserUtilsService.isMobileDevice()
                  ? ' terms-conditions-dialog'
                  : ''
              }`,
              data: response.data.dataModel,
              closeByEscape: false,
              closeByDocument: false
            })
            .then(function (result) {
              if (
                result === false ||
                (result !== undefined &&
                  dialogService.isCloseModalWithCancelAction(result))
              ) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                failureCallback();
              } else if (response.data.dataModel.id == null) {
                // FLOW-6159 - there appears to be an edge case where this function could be called twice
                // resulting in the id being set to null. I have my suspicions about the auth token expiring
                // causing this. Worse case the user clicked accept and we failed to update the DB with this
                // then on the next login they will be presented with the terms and conditions again.
                successCallback();
              } else if (result && result.accepted) {
                $http
                  .post(
                    `${APP_CONFIG.apiBaseUrl}usercondition/${response.data.dataModel.id}/accept`,
                    {
                      userConditionId: response.data.dataModel.id
                    }
                  )
                  .then(function () {
                    successCallback();
                  });
              } else {
                failureCallback();
              }
            });
        } else {
          // At present we dont have our UserId (from our Flowingly database) stored at auth0.com
          // so am using the users email to locate him/her
          successCallback();
        }
      });
  }

  function getTenants() {
    return $http
      .get<BusinessTenant[]>(`${APP_CONFIG.apiBaseUrl}business/tenants`, {
        cache: true
      })
      .then((result) => {
        return result.data;
      });
  }

  function changeTenant(tenantId) {
    return $http
      .put<IUserTokenResponse>(
        `${APP_CONFIG.apiBaseUrl}account/tenant/${tenantId}`,
        undefined
      )
      .then(async (result) => {
        await loginSuccessful(result.data.user);
        tokenService.setToken(result.data.token);
        sessionService.setuserAuthenticated();
        sessionService.getSettings().then((settings) => {
          APP_CONFIG.populateSettings(settings);
          APP_CONFIG.enableTenantSwitching = true;
          $state.go('app.login');
        });
      });
  }

  //////////// Private API Methods

  //After we logon we go back to our API to get full user details
  //As we are using the Auth0 Lock library (which has its own login box - lock.show()) we cant have login also grab our user details from our API
  //So after lock authenticates the User we need to then go back through our API to get the User's details
  //In effect that is a second level of authentication - if the user is not found there or is not active then the authentication will fail
  function getUserDetails(email, token?) {
    return sessionService
      .getUserDetails(email, token)
      .then(async function (response) {
        if (response === undefined) {
          loginFailed();
        } else {
          const res = response.data;

          if (res.success === true) {
            await loginSuccessful(res.dataModel);
            // @TODO merge this and the redirect.request.service
            // We must check that it is one of our URLs before we use it. Auth0 uses the state property as part of the auth process also.
            if (
              redirectUrl.startsWith(window.location.origin) &&
              (redirectUrl.indexOf('/flows/') > 0 ||
                redirectUrl.indexOf('/categoryId?') > 0 ||
                redirectUrl.indexOf('/form/') !== -1)
            ) {
              authLoggingApiService.log(
                `auth.service getUserDetails() was called and about to be navigated to ${redirectUrl}`
              );
              $window.location.href = redirectUrl;
            }
            if (window.isFlowinglyCordova) {
              const userId = res.dataModel.id;
              userApiService
                .registerUserMobileNotification(
                  userId,
                  window.flowinglyCordova.isIos
                )
                .then(() => {
                  if ($window.location.href.indexOf('/flowsactive') < 0)
                    $window.location.href =
                      '/flowsactive?pushNotificationHandle=' +
                      window.flowinglyCordova.pushNotificationHandle;
                });
            }
          } else {
            loginFailed();
          }
        }

        return response;
      });
  }

  async function getProfileDeferred() {
    if (APP_CONFIG.auth0UseRefreshToken) {
      const client = await angularAuth0SpaSdk.getClient();
      const profile = await client.getUser();
      return profile;
    }
    return deferredProfile.promise;
  }

  function getUser() {
    if (sessionService.isOldUserVersion()) {
      user.ver = sessionService.getUserVersion(); // Need to do this so we dont repeatedly get the user details until it has happened

      return sessionService
        .getUserDetails(user.email)
        .then(function (response) {
          if (response === undefined) {
            user.ver = 0; //Invalid
          } else {
            if (response.data.success === true) {
              const userDetails = sessionService.formatUserForLocalStorage(
                response.data.dataModel
              );
              sessionService.setUser(userDetails);
              $rootScope.user = userDetails;
              user = userDetails;
              return user;
            } else {
              user.ver = 0; //Invalid
            }
          }
          return user;
        });
    } else return user;
  }

  function getUserDeferred() {
    return deferredUser.promise;
  }

  async function loginSuccessful(userDetail: IUserDetail) {
    authLoggingApiService.log('auth.service loginSuccessful() was called');

    if (userDetail !== undefined) {
      const localUser = sessionService.formatUserForLocalStorage(userDetail);
      sessionService.setUser(localUser);
      $rootScope.user = localUser;
      user = localUser;
      await userApiService.updateLoginState(true);
      deferredUser.resolve(localUser);
    }
  }

  function loginFailed() {
    authLoggingApiService.log('auth.service loginFailed() called');
    authManager.unauthenticate();
    userProfile = null;
    user = null;
    sessionService.clear();
    setTimeout(function () {
      authLoggingApiService.log(
        'auth.service loginFailed() about to call window.location.reload() in timeout of 3000'
      );
      window.location.reload();
    }, 3000);
  }

  /**
   * Show login alert message when user use Auth0 Lock Login box
   * @param message
   */
  function addAlertMessageToLoginScreen(message: string) {
    const authHeader =
      $window.document.getElementsByClassName('auth0-lock-header')[0];
    if (authHeader) {
      const element = document.createElement('div');
      element.style.backgroundColor = '#F46C42';
      element.style.color = 'white';
      element.style.padding = '1rem';
      element.textContent = message;
      authHeader.after(element);
    }
  }

  function auth0SpaSdkAuthenticated(idToken: string) {
    return new Promise((resolve, reject) => {
      sessionService.setInitialLogin();
      tokenService.setToken(idToken);
      authManager.authenticate();

      angularAuth0SpaSdk.getClient().then((auth0Client) => {
        auth0Client
          .getUser()
          .then((profile) => {
            const userSub = profile.sub;
            getUserDetailsAuth0(userSub)
              .then((userDetails) => {
                profile = userDetails;
                sessionService.setProfile(JSON.stringify(profile));
                deferredProfile.resolve(profile);
                if (profile.identities && profile.identities.length > 0) {
                  if (
                    profile.identities[0].connection ===
                    'Username-Password-Authentication'
                  ) {
                    sessionService.setIsSso(false);
                    authLoggingApiService.log(
                      `auth.service auth0SpaSdkAuthenticated() set isSso to false and user profile identity provider was ${profile.identities[0].provider}`
                    );
                  } else {
                    sessionService.setIsSso(true);
                    authLoggingApiService.log(
                      `auth.service auth0SpaSdkAuthenticated() set isSso to true and user profile identity provider was ${profile.identities[0].provider}`
                    );
                  }
                }
                resolve(profile);
              })
              .catch((error) => {
                loginFailed();
                reject(error);
              });
          })
          .catch((error) => {
            loginFailed();
            reject(error);
          });
      });
    });
  }

  function getUserDetailsAuth0(userSub: string) {
    return $http
      .get('https://' + APP_CONFIG.auth0Domain + '/api/v2/users/' + userSub, {
        cache: true
      })
      .then((result) => {
        return result.data;
      });
  }
}

export type AuthServiceType = ReturnType<typeof authService>;
