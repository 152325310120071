import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = {
  stepAssignee: string;
  stepName: string;
};

const Header = (props: Props) => {
  const { stepAssignee, stepName } = props;
  const [actorFirstLetter, setActorFirstLetter] = useState('');
  const [avatarBgColor, setAvatarBgColor] = useState('');

  const avatarService =
    useService<SharedAngular.AvatarService>('avatarService');

  useEffect(() => {
    setupStepActorAvatar(stepAssignee);
  }, [stepAssignee]);

  function setupStepActorAvatar(actor: string) {
    if (actor) {
      const firstLetter = avatarService.getUserInitial(actor);
      setActorFirstLetter(firstLetter);
      const bgColor =
        avatarService.getBgColorForProcessMapRightPaneAvatar(firstLetter);
      setAvatarBgColor(bgColor);
    } else {
      setActorFirstLetter('?');
    }
  }

  return (
    <div className={'process-map-v2-view-right-panel-header-container'}>
      <div className={'process-map-v2-view-right-panel-header-label-row'}>
        <div className={'process-map-v2-view-right-panel-header-label-column'}>
          Step Assignee
        </div>
        <div className={'process-map-v2-view-right-panel-header-label-column'}>
          Step Name
        </div>
      </div>
      <div className={'process-map-v2-view-right-panel-header-content-row'}>
        <div
          className={'process-map-v2-view-right-panel-header-content-column'}
        >
          <Box
            className={'process-map-v2-view-right-panel-header-avatar'}
            component="span"
            sx={{
              backgroundColor: avatarBgColor
            }}
            title={stepAssignee}
          >
            {actorFirstLetter}
          </Box>
          {stepAssignee}
        </div>
        <div
          className={
            'process-map-v2-view-right-panel-header-content-column process-map-v2-view-right-panel-header-content-column-step-name'
          }
        >
          {stepName}
        </div>
      </div>
    </div>
  );
};

export default Header;
