'use strict';
import { BusinessTenant } from '@Client/runner.services/auth.service';
import angular from 'angular';

class TenantDialogController {
  static $inject = ['$scope', 'authService', 'ngDialog'];

  public modalTitle = 'Switch Tenant';
  public waitingForApi = false;
  public activeTenantId: string;
  public selectedTenantId: string;
  public userBusinessId: string;
  public tenants: BusinessTenant[];
  public activeTenantName: string;
  public userBusinessName: string;

  constructor(
    private $scope: IScopeNgDialog,
    private authService: AuthService,
    private ngDialog
  ) {
    this.activeTenantId = this.selectedTenantId =
      this.$scope.ngDialogData.tenant.id.toLowerCase();
    this.userBusinessId = this.$scope.ngDialogData.userBusinessId.toLowerCase();
    this.loadTenants();
  }

  loadTenants() {
    this.authService.getTenants().then((tenants) => {
      this.tenants = tenants.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      const activeTenant = this.tenants.find(
        (tenant) => tenant.id === this.activeTenantId
      );
      this.activeTenantName = activeTenant && activeTenant.name;
      const userBusiness = this.tenants.find(
        (tenant) => tenant.id === this.userBusinessId
      );
      this.userBusinessName = userBusiness && userBusiness.name;
    });
  }

  changeTenant(newTenantId) {
    this.waitingForApi = true;

    this.authService.changeTenant(newTenantId).then(
      (success) => {
        const selectedTenant = this.tenants.find(
          (tenant) => tenant.id === newTenantId
        );
        this.closeDialog(selectedTenant.name);
        location.reload();
      },
      (error) => {
        this.waitingForApi = false;
      }
    );
  }

  closeDialog(result) {
    this.ngDialog.closeAll(result);
  }
}

angular
  .module('flowingly.runner')
  .controller('tenantDialogController', TenantDialogController);
