/**
 * @ngdoc service
 * @name runnerCardService
 * @module flowingly.runner.services
 *
 * @description Helper service for working with card data
 */

'use strict';
import { FormGen } from '@Client/@types/formGen';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.services')
  .factory('runnerCardService', runnerCardService);

runnerCardService.$inject = [
  'guidService',
  'lodashService',
  'fgFileListService',
  'flowinglyConstants'
];

function runnerCardService(
  guidService: SharedAngular.GuidService,
  lodashService: Lodash,
  fgFileListService: FormGen.FgFileListService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const service = {
    addFileToForm: addFileToForm,
    createFileUploadData: createFileUploadData,
    formatFieldsWithCustomDBUserTypeToObject:
      formatFieldsWithCustomDBUserTypeToObject
  };

  return service;

  //////////// Public API Methods
  function addFileToForm(cardFormArray, files) {
    //
    // For reasons not yet known, the file select dialog does not update the form model.
    // Therefore we manually assign the id's of any files uploaded to the appropriate form
    // element, identified by the key passed in on the file list
    //
    if (files != undefined && files.length > 0) {
      //create a list of fileIds grouped by the key of the fileupload control they were uploaded against.
      const groupedByKey = lodashService.groupBy(files, 'key');

      //assign the field ids for each group to the appropriate field on the form
      lodashService.forEach(groupedByKey, function (group) {
        //all files in this group share this key.
        const key = group[0].key;
        const fileIds = group
          .map(function (f) {
            return f.id;
          })
          .join(',');
        const index = lodashService.findIndex(cardFormArray, function (item) {
          return item.key === key;
        });
        if (index !== -1) {
          cardFormArray[index].value = fileIds;
        }
      });
    }
    return JSON.stringify(cardFormArray);
  }

  function createFileUploadData(stepId, schema) {
    const fileUploadData = {};

    (schema.fields || []).forEach((field) => {
      const fieldType = field.type.toLowerCase();
      const isFileUpload =
        fieldType === flowinglyConstants.formFieldType.FILE_UPLOAD;

      if (isFileUpload) {
        const allFiles = fgFileListService.getFilesForStep(stepId) || [];
        allFiles.forEach((file) => {
          if (field.name == file.key) {
            fileUploadData[field.name] = JSON.stringify(file);
          }
        });
      }
    });

    return fileUploadData;
  }

  function formatFieldsWithCustomDBUserTypeToObject(data, schema) {
    (schema.fields || []).forEach((field) => {
      if (
        field.type === 'lookup' &&
        field.lookupConfig.userObject &&
        field.lookupConfig.userObject.length > 0
      ) {
        data[field.name] = JSON.stringify(field.lookupConfig.userObject);
      }

      if (
        field.type === 'selectList' &&
        field.dbDataSource &&
        guidService.isValidGuid(data[field.name])
      ) {
        let selectedUserOption;

        (field.filteredOptions || []).forEach((option) => {
          if (data[field.name] == option.value) {
            selectedUserOption = option;
          }
        });

        data[field.name] = selectedUserOption
          ? JSON.stringify(
              (({ value, text }) => ({ value, text }))(selectedUserOption)
            )
          : null;
      }
    });
    return data;
  }
}

export type RunnerCardServiceType = ReturnType<typeof runnerCardService>;
